@import '../../_all_vars'

.addTechnicalApprovalModal
    div:global(.modal)
        height: 560px !important 

.addNewApproverModal
    div:global(.modal)
        height: 445px
    .iconRow
        h2
            @include roboto-regular
            font-size: 24px
            line-height: 32px
            word-wrap: break-word
    .modalContent
        margin-top: 24px
        display: flex
        flex-direction: column
        gap: 24px

        .userDetails
            display: flex
            flex-direction: column
            gap: 18px
            .label
                @include roboto-medium
                font-size: 14px
            .userEmail
                @include roboto-regular
                font-size: 14px

        .dropdownContainer
            height: 80px
            .roleDrodownlabel
                @include roboto-medium
                font-size: 14px !important
                line-height: 20px
                letter-spacing: 0.1px
                text-align: left
                display: inline-block
                margin-bottom: 8px
            .roleDropdown
                padding-top: 8px
                position: absolute
                width: clamp(100px, 100%, 452px)


        .errorMessage
            @include roboto-regular
            font-size: 12px
            line-height: 16px
            margin-left: auto
            color: $automation-centre-form-message-error
            margin-top: 8px
            width: 100%
    
    .secondaryButton
        border-radius: 20px !important
        padding: 8px 16px !important
        @include atc-btn-secondary

    .primaryButton
        border-radius: 20px !important
        padding: 8px 16px !important
        @include atc-btn-primary-green