@import './_all_vars.sass'

.comments
  display: flex
  align-items: center
  justify-content: flex-start
  margin-right: 4px
  button
    border: none
    background: transparent
    color: $automation-centre-likes-enabled
    cursor: pointer
    &:hover
        color: black

  .numberOfComments
    font-size: inherit
    padding: 0px 4px
    min-width: 12px
    justify-content: flex-start