@import '../../_all_vars.sass'

.permissionHistoryModal      
    div:global(.modal)
        height: 724px
        i:global(.modal-close-icon)
            color: $automation-centre-aurecon-grey-primary !important
            background: none
    .iconRow
        h2
            @include roboto-regular
            font-size: 24px
            line-height: 32px
            word-wrap: break-word
    .modalContent
        margin-top: 24px
        height: 100%
        display: flex
        flex-direction: column
        gap: 24px

        .tableContainer
            margin-top: 40px
            

            
            .actionHistory
                display: inline-flex
                gap: 8px
                justify-content: center
                align-items: center

            table
                thead
                    tr
                        border: none !important
                    td
                        @include roboto-medium
                        background-color: $automation-centre-upload-automation-column-background !important
                        padding: 12px !important
                        div
                            display: inline-flex
                            align-items: center
                        &:hover
                            background-color: $automation-centre-aurecon-grey-primary !important
                            color: $automation-centre-background-light-primary !important
                        &:active
                            background-color: $automation-centre-aurecon-grey-secondary !important
                            color: white !important
                tbody
                    tr
                        &:hover
                            td
                                background-color: $automation-centre-text-menu-background-hover !important
    .secondaryButton
        border-radius: 20px !important
        padding: 8px 16px !important
        @include atc-btn-secondary

    .primaryButton
        border-radius: 20px !important
        padding: 8px 16px !important
        @include atc-btn-primary-green
    
    div:global(.modal-actions)
        button
            @include atc-btn-secondary