@import './_all_vars.sass'

.uploadScreen
  padding-top: 40px
  padding-left: 40px
  padding-right: 40px

  .uploadColumnsContainer
    display: flex
    grid-template-columns: 1fr 40px 1fr
    padding-top: 40px

    .fieldsColumnContainer
      min-width: 450px
      max-width: 48%
      margin: 10px auto
    
  .pageHeading
    @include roboto-regular
    font-size: 28px

  .metadataSection
    background-color: $automation-centre-upload-automation-column-background
    border-radius: 8px
    padding-bottom: 24px

  .metadataSpacerRow
    background-color: $automation-centre-background-light-primary
    height: 40px

  .metadataFillerRow
    background-color: $automation-centre-background-light-primary
    padding-bottom: auto

  .metadataSectionHeading
    @include roboto-medium
    font-size: 22px
    line-height: 28px

  .metadataRow
    padding-top: 24px
    padding-left: 24px
    padding-right: 24px

  .metadataCheckboxRow
    display: flex
    width: 100%
    padding-top: 24px
    padding-left: 24px
    padding-right: 24px

  .metadataFieldRow
    padding-top: 8px

  .metadataFieldCheckbox
    align-items: center
    justify-content: center
    width: 7%

  .metadataDropDownBox
    padding-top: 8px
    width: 100%

  .titleCharacters
    @include roboto-regular
    font-size: 12px
    line-height: 16px
    margin-left: auto
    color: #696C67
    margin-top: 8px

  .hasError
    div:global(.dropdown-selected) 
        border: 1px solid $automation-centre-form-message-error !important
    
    :global(.input-field.is-dropdown > input) 
        border: 1px solid $automation-centre-form-message-error !important

  .errorMessage
    @include roboto-regular
    font-size: 12px
    line-height: 16px
    margin-left: auto
    color: $automation-centre-form-message-error
    margin-top: 8px
    width: 100%

  .metadataTextInput
    width: auto

    input
      @include roboto-regular
      border-radius: 4px
      background-color: $automation-centre-upload-automation-input-background

    :global(.input-field.is-error > input)
      border: 1px solid $automation-centre-form-message-error !important

    :global(.input-field > input)
      border: 1px solid $automation-centre-upload-automation-input-border !important

    i:global(.status-icon)
      display: none

    span:global(.input-error)
      color: $automation-centre-form-message-error

  .uploadInputContainer
    width: 100%

    .uploadUrlHeading
      @include roboto-medium
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.1px
      text-align: left
      display: inline-block
      

    .uploadUrlInputBox
      display: flex
      justify-content: space-between
      align-items: center
      width: 100%
      padding-top: 8px

      .uploadUrlInput
        width: 100%

      .hasError
        border: 1px solid $automation-centre-form-message-error !important

      .uploadUrlField
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        border-radius: 4px
        border: 1px solid $automation-centre-upload-automation-input-border
        background-color: $automation-centre-upload-automation-input-background
        margin-right: 10px

        .urlIcon
          font-size: 16px
          margin: auto 10px

        .protocolLabel
          font-size: 14px
        
        input
          border-bottom: none
          padding-left: 5px

        i:global(.status-icon)
          display: none

        span:global(.input-error)
          color: $automation-centre-form-message-error

      .uploadUrlOpenButton
        width: 40px
        margin: 0px auto auto auto


  .uploadFileHeader 
    @include roboto-medium
    font-size: 14px
    line-height: 20px
    padding-bottom: 8px
    display: inline-block

  .uploadFileInstruction,
  .uploadSafelistingInstruction
    @include roboto-regular
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.25px
    text-align: left

    .uploadLink
      color: black
      font-size: 14px
      cursor: pointer
    
  .uploadFileInstruction
    padding-bottom: 24px

  .uploadSafelistingInstruction
    width: 93%


  .metadataFieldlabel
    @include roboto-medium
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.1px
    text-align: left
    display: inline-block

  p
    @include roboto-regular
  
  li
    @include roboto-regular

  .metadataTextBox
    width: 100%

    .metadataLongText
        textarea
          resize: none
          @include roboto-regular
          border-radius: 4px

        :global(.textarea-field > textarea)
          border: 1px solid $automation-centre-upload-automation-input-border !important

    .hasError
        :global(.textarea-field > textarea)
          border: 1px solid $automation-centre-form-message-error !important

        span:global(.input-error)
          color: $automation-centre-form-message-error
          margin: 5px

  .metadataPlainText
    @include roboto-regular
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.25px
    padding-top: 8px

  .metadataPillsBox
    display: flex
    flex-wrap: wrap
    gap: 8px

    .metadataPill
      border-radius: 40px
      padding-bottom: 8px

      span
        @include roboto-regular
        font-size: 12px

      &.capability
        background-color: $automation-centre-upload-automation-pill-capability

      &.practise
        background-color: $automation-centre-upload-automation-pill-practise

      &.region
        background-color: $automation-centre-upload-automation-pill-region

      &.originator
        background-color: $automation-centre-upload-automation-pill-originator

  .uploadFooter
    display: flex
    flex-direction: column
    padding-top: 40px

  .uploadDivider
    border-bottom: 1px solid $automation-centre-divider-color

  .uploadButtonBox
    display: flex
    justify-content: flex-end
    gap: 8px
    padding-top: 40px
    padding-bottom: 40px

    .uploadButton
        @include roboto-medium
        height: 40px
        border-radius: 20px
        padding: 8px 16px 8px 12px
        line-height: 20px
        letter-spacing: 0.1px
        font-size: 14px
        cursor: pointer

    .uploadButtonPrimary
        color: $automation-centre-aurecon-grey-primary 
        background-color: $automation-centre-aurecon-primary-green 
        border: 1px solid $automation-centre-aurecon-primary-green

        &:hover
            border: 1px solid $automation-centre-aurecon-grey-primary 
            background-color: $automation-centre-aurecon-grey-primary 
            color: $automation-centre-background-light-primary 
            
        &:active
            background-color: $automation-centre-aurecon-grey-secondary 
            color: $automation-centre-background-light-primary 

        &:disabled
            border: 1px solid $automation-centre-button-primary-background-disabled 
            background-color: $automation-centre-button-primary-background-disabled
            color: $automation-centre-button-primary-color-disabled
            cursor: default

    .uploadButtonSecondary
        color: $automation-centre-aurecon-grey-secondary 
        background-color: $automation-centre-background-light-primary
        border: 1px solid $automation-centre-aurecon-grey-primary

        &:hover
            background-color: $automation-centre-aurecon-grey-primary 
            color: $automation-centre-background-light-primary

        &:active
            background-color: $automation-centre-aurecon-grey-secondary 
            color: $automation-centre-background-light-primary

  .scriptLibraryUploadBox
    display: flex
    width: 100%
    align-items: center
    justify-content: space-between

    .leftColumn:
      @include roboto-regular
      font-size: 14px 
      line-height: 20px

    .scriptLibraryButton
        @include roboto-medium
        height: 40px
        border-radius: 20px
        padding: 8px 16px 8px 12px
        line-height: 20px
        letter-spacing: 0.1px
        font-size: 14px
        color: $automation-centre-aurecon-grey-primary 
        background-color: $automation-centre-aurecon-primary-green 
        border: 1px solid $automation-centre-aurecon-primary-green

        &:hover
            border: 1px solid $automation-centre-aurecon-grey-primary 
            background-color: $automation-centre-aurecon-grey-primary 
            color: $automation-centre-background-light-primary 
            
        &:active
            background-color: $automation-centre-aurecon-grey-secondary 
            color: $automation-centre-background-light-primary 

        &:disabled
            border: 1px solid $automation-centre-button-primary-background-disabled 
            background-color: $automation-centre-button-primary-background-disabled
            color: $automation-centre-button-primary-color-disabled

  .fieldDetails
    font-weight: 400
    font-size: 14px
    line-height: 20px

    .uploadGuidanceUrl
      color: $automation-centre-dark-text-color
      font-size: 14px
      font-weight: 400
      line-height: 20px
      cursor: pointer

    .uploadGuidanceIcon
        margin: 0px 5px

.uploadLoader
  display: flex
  justify-content: center
  height: 100%


.textFieldLimit 
  font-family: Arial, sans-serif
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 18px
  color: rgba(0,0,0,0.6)
  float: right
  margin-top: 10px
