@import './_all_vars.sass'

.divider
    border-bottom: 1px solid $automation-centre-divider-color
    margin: 40px 

.commaSeparator
    white-space: nowrap

.controls
    justify-content: flex-end
    gap: 16px
    align-content: center 
    flex-wrap: nowrap !important

    .deactivateButton
        @include atc-btn-secondary
        border-radius: 20px
        i
            font-size: 17px
            color: $automation-centre-status-warning
        
    .reactivateButton
        @include atc-btn-secondary
        border-radius: 20px
        i
            font-size: 17px


    .viewButton
        @include roboto-medium
        color: $automation-centre-aurecon-grey-primary !important
        background-color: $automation-centre-view-btn-color !important
        border-radius: 20px
        padding: 8px 16px 8px 12px
        line-height: 20px
        letter-spacing: 0.1px
        i
            font-size: 17px
        &:hover
            background-color: $automation-centre-aurecon-grey-primary !important
            color: white !important
        &:active
            background-color: $automation-centre-aurecon-grey-secondary !important
            color: white !important
        
        &:disabled
            border: 1px solid $automation-centre-button-primary-background-disabled  !important
            background-color: $automation-centre-button-primary-background-disabled !important
            color: $automation-centre-button-primary-color-disabled !important
            cursor: default

    .downloadButton
        color: $automation-centre-dark-text-color !important
        background-color: $automation-centre-view-btn-color !important
        border-radius: 20px
        padding: 8px 16px 8px 12px
        i
            font-family: 'Material Icons Outlined'
        span
            @include roboto-medium
        &:hover
            background-color: $automation-centre-aurecon-grey-primary !important
            border: 1px solid $automation-centre-aurecon-grey-primary !important
            color: white !important
        
        &:active
            background-color: $automation-centre-aurecon-grey-secondary !important
            border: 1px solid $automation-centre-aurecon-grey-secondary !important
            color: white !important   
    div
        align-content: center

.copyIcon
    cursor: pointer

.unlikedIcon
    cursor: pointer

.likedIcon
    color: $automation-centre-aurecon-grey-secondary
    
.sectionHeader
    h2  
        @inlcude roboto-medium
        color: $automation-centre-heading
        font-size: 22px
        font-weight: 500
        line-height: 28px
        word-wrap: break-word


.detailRow
    @include roboto-regular
    display: flex
    align-items: center
    color: $automation-centre-viewer-text-color
    font-size: 14px
    line-height: 20px
    margin: 12px 0
    .fieldTitle
        font-weight: 500
        letter-spacing: 0.10px
        word-wrap: break-word
        padding: 0 16px 0 0
    .content
        font-weight: 400
        word-wrap: break-word
        letter-spacing: 0.25px
        overflow-wrap: break-word
        max-width: 120ch
        word-break: normal

.approverComment
    display: flex
    flex-direction: column
    font-size: 14px !important
    line-height: 20px
    .title
        font-weight: 500
        letter-spacing: 0.10px
        word-wrap: break-word
        padding: 0 16px 0 0
    .comment
        font-size: 14px !important
        font-weight: 400
        word-wrap: break-word
        letter-spacing: 0.25px
        margin-top: 8px

.comments
    .commentTextArea
        margin-bottom: 40px
        span
            margin-top: 0px !important
        textarea
          resize: none
          @include roboto-regular
          border-radius: 4px

        :global(.textarea-field > textarea)
          border: 1px solid $automation-centre-upload-automation-input-border !important

    .hasError
        :global(.textarea-field > textarea)
            border: 1px solid $automation-centre-form-message-error !important

        span:global(.input-error)
          color: $automation-centre-form-message-error
          margin: 5px

    .commentButtons
        display: flex
        justify-content: flex-end
        button
            @include roboto-medium
            background-color: white !important
            margin: 0 0 0 16px 
            border-radius: 20px
            padding: 8px 16px
            font-size: 14px
            line-height: 20px
            letter-spacing: 0.10px
            border: 1px solid $automation-centre-upload-automation-input-border
            
            &:hover
                background-color: $automation-centre-aurecon-grey-primary !important
                color: white !important
            &:active
                background-color: $automation-centre-aurecon-grey-secondary !important
                color: white !important
            &:disabled
                border: 1px solid $automation-centre-button-primary-color-disabled
                color: $automation-centre-button-primary-color-disabled !important
                i
                    color: $automation-centre-button-primary-color-disabled
                &:hover
                    background-color: white !important
                    color: $automation-centre-button-primary-color-disabled !important
        
        .reject
            i
                color: $automation-centre-status-warning
        
        .actionRequired
            i
                color: $automation-centre-status-info

        .approve
            i
                color: $automation-centre-status-success
        
        .acceptForTracking
            i
                color: $automation-centre-status-tracking
        
        .confirmAction
            i
                color: $automation-centre-aurecon-grey-secondary

.actionNotRequiredModal
    @include roboto-regular

    .iconRow
        justify-content: flex-start
        align-items: center
        display: flex
        .icon
            align-content: center
            height: 24px
            width: 24px
            border-radius: 24px
            margin-right: 8px
            background-color: white
            color: #F6D510

    .modalContent
        font-weight: 400
        font-size: 14px
        line-height: 20px
        letter-spacing: 0.25px
        word-wrap: 'break-word'
    .actionDeny
        font-weight: 500
        border-radius: 20px !important
        background-color: white !important
        padding: 8px 16px !important
        border-color: $automation-centre-upload-automation-input-border !important
        color: $automation-centre-upload-automation-input-border !important
        &:hover
            border: 1px solid $automation-centre-aurecon-grey-primary !important
            background-color: $automation-centre-aurecon-grey-primary !important
            color: white !important
        &:active
            background-color: $automation-centre-aurecon-grey-secondary !important
            color: white !important
    div:global(.modal)
        i:global(.modal-close-icon)
            color: $automation-centre-aurecon-grey-primary !important
       

.approverModal
    @include roboto-regular
    .iconRow
      justify-content: flex-start
      align-items: center
      display: flex

      .icon
        align-content: center
        height: 24px
        width: 24px
        border-radius: 24px
        margin-right: 8px
        background-color: white

    .modalContent
        font-weight: 400
        font-size: 14px
        line-height: 20px
        letter-spacing: 0.25px
        word-wrap: 'break-word'
    .actionConfirm
        border-radius: 20px !important
        padding: 8px 16px !important
        border-color: $automation-centre-upload-automation-input-border !important
        color: $automation-centre-upload-automation-input-border !important
        font-weight: 500
        &:focus
            background-color: white !important
        &:hover
            background-color: $automation-centre-aurecon-grey-primary !important
            color: white !important
        &:active
            background-color: $automation-centre-aurecon-grey-secondary !important
            color: white !important
    .actionDeny
        font-weight: 500
        border-radius: 20px !important
        background-color: $automation-centre-view-btn-color !important
        padding: 8px 16px !important
        border-color: $automation-centre-view-btn-color !important
        color: $automation-centre-upload-automation-input-border !important
        &:hover
            border: 1px solid $automation-centre-aurecon-grey-primary !important
            background-color: $automation-centre-aurecon-grey-primary !important
            color: white !important
        &:active
            background-color: $automation-centre-aurecon-grey-secondary !important
            color: white !important
    div:global(.modal)
        i:global(.modal-close-icon)
            color: $automation-centre-aurecon-grey-primary !important

.AutomationInformationHeader
    .title
        h1
            @include roboto-medium
            font-size: 22px
            line-height: 28px

    .controls
        .shareButton
            color: $automation-centre-dark-text-color
            border-color: $automation-centre-dark-text-color !important
            border-radius: 20px
            padding: 8px 16px 8px 12px
            i
                font-family: 'Material Icons Outlined'
            span
                @include roboto-medium
            &:hover, &:focus
                background-color: $automation-centre-aurecon-grey-primary !important
                border: 1px solid $automation-centre-aurecon-grey-primary !important
            
            &:active
                background-color: $automation-centre-aurecon-grey-secondary !important
                border: 1px solid $automation-centre-aurecon-grey-secondary !important

        .downloadButton
            color: $automation-centre-dark-text-color !important
            background-color: $automation-centre-view-btn-color !important
            border-radius: 20px
            padding: 8px 16px 8px 12px
            i
                font-family: 'Material Icons Outlined'
            span
                @include roboto-medium
            &:hover
                background-color: $automation-centre-aurecon-grey-primary !important
                border: 1px solid $automation-centre-aurecon-grey-primary !important
                color: white !important
            
            &:active
                background-color: $automation-centre-aurecon-grey-secondary !important
                border: 1px solid $automation-centre-aurecon-grey-secondary !important
                color: white !important

.guidanceMaterialUrl
    color: $automation-centre-dark-text-color
    font-size: 14px
    font-weight: 400
    line-height: 20px
    cursor: pointer

.guidanceMaterialIcon
    margin: 0px 5px

.authorButtons
        display: flex
        justify-content: flex-end
        button
            @include roboto-medium
            background-color: white !important
            margin: 0px 0 0 16px 
            border-radius: 20px
            padding: 8px 16px
            font-size: 14px
            line-height: 20px
            letter-spacing: 0.10px
            border: 1px solid $automation-centre-upload-automation-input-border
            
            &:hover
                background-color: $automation-centre-aurecon-grey-primary !important
                color: white !important
            &:active
                background-color: $automation-centre-aurecon-grey-secondary !important
                color: white !important

        .close, .edit
            border: 1px solid $automation-centre-upload-automation-input-border !important
            &:disabled
                background-color: white !important
                &:hover
                    color: black !important
                
.verificationWrapper
    background-color: $automation-centre-upload-automation-column-background !important
    margin-top: 20px
    margin-bottom: 20px
    border: none !important
    .accordion
        border: none !important
        background-color: $automation-centre-upload-automation-column-background !important
    .accordionPanel
        border: none !important
        background-color: $automation-centre-upload-automation-column-background !important
        .headingHolder
            display: flex
            justify-content: space-between
            align-items: center
            .verificationHeading
                @include roboto-medium
                padding: 20px
                font-size: 22px
                line-height: 28px
                display: flex
                flex-direction: row
                align-items: center
            .verificationHeadingDate
                @include roboto-regular
                font-size: 14px
                line-height: 20px
                margin-left: auto
                padding-right: 24px



    .verification
        border-radius: 8px
        .verificationHolder
            padding-bottom: 24px
            .overrideStatus
                display: inline-flex
                align-items: center

                .arrow
                    margin-right: 8px
        
            .verificationStatus
                padding-left: 20px
                span
                    @include roboto-medium
                    font-size: 14px !important
            .newVerificationStatus
                span
                    @include roboto-medium
                    font-size: 14px !important
            
            .verificationDate
              color: $automation-centre-dropdown-primary
            p
                padding-left: 20px
                margin-top: 8px
                font-size: 12px
                line-height: 16px

            .verificationCommentContainer
                padding-left: 20px

.verificationInformationHeader
    @include roboto-medium
    padding: 20px 0px
    font-size: 22px

@media screen and (max-width: $breakpoint--mobile + 1) 
    .detailRow
        .content
            padding: 16px 0 0 0
    
    .controls
        justify-content: flex-start

@media screen and (max-width: $breakpoint--tablet + 1)
    .detailRow
        .content
            padding: 16px 0 0 0


.viewerLoader
  display: flex
  justify-content: center
  height: 100%
    
.headerTitle
    h1
        letter-spacing: 0.25px
        overflow-wrap: break-word
        max-width: 65%
        word-break: normal

.automationDescription
    @include roboto-regular
    margin-top: 8px
    font-size: 14px
    line-height: 20px
    display: inline
    white-space: pre-wrap
    word-break: break-word

.confirmModal
    :global(i)
        color: $automation-centre-status-warning
        