@import './_all_vars.sass'

.tableContainer
    margin-top: 40px

    table
        thead
            tr
                border: none !important
            td
                @include roboto-medium
                background-color: $automation-centre-upload-automation-column-background !important
                padding: 12px !important
                div
                    display: inline-flex
                    align-items: center
                &:hover
                    background-color: $automation-centre-aurecon-grey-primary !important
                    color: $automation-centre-background-light-primary !important
                &:active
                    background-color: $automation-centre-aurecon-grey-secondary !important
                    color: white !important
        tbody
            tr
                &:hover
                    td
                        background-color: $automation-centre-text-menu-background-hover !important
                td
                    max-width: 100px !important
                    background: green

    .hasError
        border: 1px solid $automation-centre-form-message-error !important

    .uploadUrlFieldContainer
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%
        border-radius: 4px
        border: 1px solid $automation-centre-upload-automation-input-border
        background-color: $automation-centre-upload-automation-input-background
        margin-right: 10px
        padding: 0px 10px

        .uploadUrlField
            input
                border: 0px

    .actionContainer
        display: flex
        justify-content: center
        
        .iconButton
            background: $automation-centre-aurecon-grey-secondary
            border-radius: 50%
            padding: 10px
            color: white
            cursor: pointer

        .iconDisabled
            background: $automation-centre-aurecon-grey-light
            cursor: not-allowed

    .urlColumn
        display: flex
        padding-top: 20px !important

    .sourceColumn
        min-width: 250px
        width: 300px
    
    .actionColumn
        min-width: 100px
        width: 150px

    .errorMessage
        @include roboto-regular
        font-size: 12px
        line-height: 16px
        margin-left: auto
        color: $automation-centre-form-message-error
        margin-top: 8px
        width: 100%
