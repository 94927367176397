@mixin atc-btn-primary-green
    @include roboto-medium
    letter-spacing: 0.10px
    font-size: 14px
    line-height: 20px
    color: $automation-centre-aurecon-grey-primary !important
    background-color: $automation-centre-aurecon-primary-green !important
    border: 1px solid $automation-centre-aurecon-primary-green !important

    &:hover
        border: 1px solid $automation-centre-aurecon-grey-primary !important
        background-color: $automation-centre-aurecon-grey-primary !important
        color: $automation-centre-background-light-primary !important
        
    &:active
        background-color: $automation-centre-aurecon-grey-secondary !important
        color: $automation-centre-background-light-primary !important

    &:disabled
        border: 1px solid $automation-centre-button-primary-background-disabled !important
        background-color: $automation-centre-button-primary-background-disabled !important
        color: $automation-centre-button-primary-color-disabled !important
        cursor: default !important

@mixin atc-btn-primary-grey
    @include roboto-medium
    letter-spacing: 0.10px
    font-size: 14px
    line-height: 20px
    color: $automation-centre-background-light-primary !important
    background-color: $automation-centre-aurecon-grey-secondary !important
    border: 1px solid $automation-centre-aurecon-grey-secondary !important

    &:hover
        border: 1px solid $automation-centre-aurecon-grey-primary !important
        background-color: $automation-centre-aurecon-grey-primary !important
        color: $automation-centre-background-light-primary !important
        
    &:active
        background-color: $automation-centre-aurecon-grey-secondary !important
        color: $automation-centre-background-light-primary !important

    &:disabled
        border: 1px solid $automation-centre-button-primary-background-disabled !important
        background-color: $automation-centre-button-primary-background-disabled !important
        color: $automation-centre-button-primary-color-disabled !important
        cursor: default !important

@mixin atc-btn-secondary
    @include roboto-medium
    letter-spacing: 0.10px
    font-size: 14px
    line-height: 20px
    color: $automation-centre-aurecon-grey-primary !important
    background-color: $automation-centre-background-light-primary !important
    border: 1px solid $automation-centre-aurecon-grey-primary !important

    &:hover
        border: 1px solid $automation-centre-aurecon-grey-primary !important
        background-color: $automation-centre-aurecon-grey-primary !important
        color: $automation-centre-background-light-primary !important
        
    &:active
        background-color: $automation-centre-aurecon-grey-secondary !important
        color: $automation-centre-background-light-primary !important

    &:disabled
        border: 1px solid $automation-centre-button-primary-background-disabled !important
        background-color: $automation-centre-button-primary-background-disabled !important
        color: $automation-centre-button-primary-color-disabled !important
        cursor: default !important