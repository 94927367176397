@font-face {
    font-family: 'Meta Pro';
    src: url('../assets/fonts/metapro_normal.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@mixin roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
}

@mixin roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

@mixin roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

@mixin roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

@mixin roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
}

@mixin roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
}

@mixin roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
}

@mixin roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
}

@mixin roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
}

@mixin roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
}

@mixin roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
}

// Meta Pro font

@mixin meta-pro-regular {
    font-family: "Meta Pro", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin meta-pro-medium {
    font-family: "Meta Pro", sans-serif;
    font-weight: 500;
    font-style: normal;
}

@mixin meta-pro-bold {
    font-family: "Meta Pro", sans-serif;
    font-weight: 700;
    font-style: normal;
}

@mixin meta-pro-black {
    font-family: "Meta Pro", sans-serif;
    font-weight: 900;
    font-style: normal;
}

@mixin meta-pro-thin-italic {
    font-family: "Meta Pro", sans-serif;
    font-weight: 100;
    font-style: italic;
}

@mixin meta-pro-light-italic {
    font-family: "Meta Pro", sans-serif;
    font-weight: 300;
    font-style: italic;
}

@mixin meta-pro-regular-italic {
    font-family: "Meta Pro", sans-serif;
    font-weight: 400;
    font-style: italic;
}

@mixin meta-pro-medium-italic {
    font-family: "Meta Pro", sans-serif;
    font-weight: 500;
    font-style: italic;
}

@mixin meta-pro-bold-italic {
    font-family: "Meta Pro", sans-serif;
    font-weight: 700;
    font-style: italic;
}

@mixin meta-pro-black-italic {
    font-family: "Meta Pro", sans-serif;
    font-weight: 900;
    font-style: italic;
}                       