@import './_all_vars.sass'
.layoutContainer
  height: 100dvh
  display: flex
  flex: 1
  flex-direction: column
  align-items: center
  justify-content: center
  .profileIcon
    margin-bottom: 24px
  .email
    @include roboto-regular
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.25px
    margin: 0 0 8px 0

  .role
    @include roboto-regular
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.25px
    margin: 0 0 8px 0

  button
    height: 40px !important
    width: 77px !important
    border-radius: 20px
    margin-top: 24px
    @include atc-btn-primary-green

    

