@import './_all_vars.sass'

.automationFilters
  background-color: white
  padding: 34px 24px
  .filterContainer
    display: flex
    gap: 10px
    border-radius: 8px
    display: flex
    flex-direction: row
    align-items: center
    flex-wrap: nowrap
    .filterText
      @include roboto-medium
      font-size: 14px
    .filterDropdownsContainer
      width: 80%
      display: flex
      flex-direction: row
      .filterDropdown
        width: 100%
        padding: 16px
        display: flex
        align-items: center
        justify-content: space-between
        p
          @include roboto-regular
          font-size: 14px
          line-height: 20px
          display: flex
          align-items: center
          flex: 1

        .pill
          margin-left: auto
          margin-right: 5px
          @include roboto-regular
          font-size: 12px
          line-height: 16px
          letter-spacing: 0.4px
          text-align: left
        button
          color: $automation-centre-aurecon-grey-primary
          font-weight: 500
          font-size: 14px
          line-height: 20px
    .controls
      width: 15%
      display: flex
      gap: 10px
      justify-content: space-between
      align-items: center

      .applyButton
        @include atc-btn-primary-green
        border-radius: 20px !important
        padding: 16px 16px !important
        display: flex
        align-items: center !important
      .clearFilter
        @include roboto-medium
        font-size: 14px
        color: $automation-centre-aurecon-grey-primary
        white-space: nowrap
        cursor: pointer
        &:hover
          color: $automation-centre-aurecon-primary-green
      .accordionToggle
        min-width: 40px
        height: 40px
        background: $automation-centre-aurecon-grey-secondary
        color: white 
        border: none 

        &:focus
          background: $automation-centre-aurecon-grey-secondary
          color: white 
          border: none  !important
       
        &.active
          background: $automation-centre-button-primary-background-disabled !important
          color: $automation-centre-button-primary-color-disabled !important
          border: none !important
  .pillsContainer
    .pills
      display: flex
      flex-direction: row
      align-items: center
      padding: 8px 0
      .title
        align-self: flex-start !important
        width: 63px
        margin-top: 10px
        margin-right: 8px
        color: $automation-centre-aurecon-grey-light
        font-size: 14px
        @include roboto-medium

        

  @media screen and (max-width: $breakpoint--tablet + 1)
    .filterContainer
      display: flex
      flex-direction: column !important
      justify-content: flex-start !important
      align-items: flex-start !important

      .filterDropdownsContainer
        width: 100%
        display: flex
        flex-direction: column
      
      .controls
        width: 100%
        justify-content: flex-end
        gap: 10px