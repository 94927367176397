@import './_all_vars.sass'

.commonModal
  @include roboto-regular

  .modalTitle
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.25px
    text-align: left

  .modalContent
    font-weight: 400
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.25px
    text-align: left


  .iconRow
    justify-content: flex-start
    align-items: center
    display: flex

    .icon
      align-content: center
      height: 24px
      width: 24px
      border-radius: 24px
      margin-right: 8px
      background-color: white

  .modalContainer
    color: $automation-centre-main-dark

    .confirmModalIcon
      margin: 0px 20px 2px 0px 

    .message
      white-space: pre-wrap
      font-weight: 400
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.25px
      text-align: left

  .headerRow
    @include meta-pro-regular
    font-size: 24px
    font-weight: 400
    line-height: 32px
    text-align: left

  .secondaryButton
    border-radius: 20px !important
    padding: 8px 16px !important
    @include atc-btn-secondary

  .primaryButton
    border-radius: 20px !important
    padding: 8px 16px !important
    @include atc-btn-primary-green
      
  div:global(.modal)
    width: 480px
    i:global(.modal-close-icon)
        color: $automation-centre-aurecon-grey-primary !important


