@import '../../_all_vars'

.editContainer
    display: inline-flex
    align-items: center
    margin: 0 24px 0 0px
    .editButton 
        color: $automation-centre-aurecon-grey-secondary
        cursor: pointer
.editApprovalModal
    div:global(.modal)
        height: 344px !important   
    .iconRow
        h2
            @include roboto-regular
            font-size: 24px
            line-height: 32px
            word-wrap: break-word
    .modalContent
        margin-top: 24px
        display: flex
        flex-direction: column
        gap: 24px

        .dropdownContainer
            .statusDrodownlabel
                @include roboto-medium
                font-size: 14px !important
                line-height: 20px
                letter-spacing: 0.1px
                text-align: left
                display: inline-block
                margin-bottom: 8px
            .statusDropdown
                padding-top: 8px
                position: absolute
                width: clamp(100px, 100%, 452px)


        .errorMessage
            @include roboto-regular
            font-size: 12px
            line-height: 16px
            margin-left: auto
            color: $automation-centre-form-message-error
            margin-top: 8px
            width: 100%
            display: flex
            bottom: 5px
    
    .secondaryButton
        border-radius: 20px
        padding: 8px 16px
        @include atc-btn-secondary

    .primaryButton
        border-radius: 20px 
        padding: 8px 16px 
        @include atc-btn-primary-green
.status
    @include roboto-medium

.approvalStatus
    span
      @include roboto-regular
      font-size: 12px !important
      line-height: 16px
      letter-spacing: 0.4px
      text-align: left  