@import '../_all_vars.sass'

.mainContainer
	display: flex
	flex-direction: column
	width: 100%

.editor 
	@include roboto-regular
	font-size: 14px
	min-height: 43px
	box-sizing: border-box
	border-radius: 4px
	border: 1px solid $automation-centre-aurecon-grey-primary
	padding: 10px 16px 8px 16px
	height: 87px
	resize: vertical
	overflow: auto
	-ms-overflow-style: none
	scrollbar-width: none
	&::-webkit-scrollbar
		display: none
	&:hover, &:active, &:focus, &:focus-within
		border: 1px solid $automation-centre-aurecon-green-primary

	.mention 
		@include roboto-regular
		font-size: 14px
		line-height: 20px
		letter-spacing: 0.25px
		color: $aurecon-centre-mention-color
		text-decoration: none

.editorReply
	composes: editor
	height: 43px

.characterCount
	align-self: flex-end
	color: rgba(0, 0, 0, 0.6)
	font-size: 12px
	font-style: normal
	font-weight: 400
	line-height: 18px
	margin-top: 8px
	



.mentionSuggestions 
	background: white
	max-height: 392px
	width: 430px
	overflow-y: auto 
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.30) 
	border-radius: 2px
	cursor: pointer
	display: flex
	flex-direction: column
	box-sizing: border-box
	transform-origin: 50% 0%
	transform: scaleY(0)
	z-index: 1000


	.mentionSuggestionsEntryContainer 
		display: table
		width: 100%

		.mentionSuggestionsEntryText
			white-space: nowrap
			overflow: hidden
			text-overflow: ellipsis


.mentionSuggestionsEntry 
	padding: 18px 16px
	transition: background-color 0.2s cubic-bezier(.27,1.27,.48,.56)
	&:active 
		background-color: $automation-centre-aurecon-grey-secondary
		color: white

.mentionSuggestionsEntryFocused 
	composes: mentionSuggestionsEntry
	color: white
	background-color: $automation-centre-aurecon-grey-secondary








		

