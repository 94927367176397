@import './_all_vars.sass'
.likes
  display: flex
  align-items: center
  justify-content: flex-start
  margin-right: 4px
  button
    border: none
    background: transparent

  svg
    cursor: pointer
    fill: $automation-centre-likes-enabled
    &:hover
      fill: black
  .liked
    svg
      fill: black

  .numberOfLikes
    font-size: inherit
    padding: 0px 4px
    min-width: 12px
    justify-content: flex-start
