@import './_all_vars.sass'


.pillHolder
    border: none
    background: transparent

.tagPill
    @include roboto-regular
    font-size: 12px !important
    line-height: 16px
    letter-spacing: 0.40px
    text-wrap: nowrap
    color: $automation-centre-pill-text-color
    border-radius: 40px !important
    padding: 4px 12px !important
    margin: 4px 8px 4px 0
    cursor: pointer

.source
    background-color: $automation-centre-upload-automation-pill-source

.capability
    background-color: $automation-centre-upload-automation-pill-capability

.practice
    background-color: $automation-centre-upload-automation-pill-practise
.region
    background-color: $automation-centre-upload-automation-pill-region

.pillIcon
    margin: 0px 5px

.pillTooltip
    font-size: 14px

