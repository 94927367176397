@import './_all_vars.sass'

.container
  display: flex
  flex-direction: column
  padding: 110px 106px
  height: 100dvh

  .logoBackground
    width: clamp(100px, 100%, 251px)
    position: absolute
    bottom: 77px
    left: calc(100vw - 43px - 251px)
    z-index: -1
  
  .aureconLogo
    img
      height: 24px
     
  .contents
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    img
      width: clamp(100px, 100%, 383px) !important
      margin-bottom: 11px
    .line1
      @include roboto-regular
      color: $automation-centre-aurecon-grey-primary
      font-size: 36px
      line-height: 36px
      word-wrap: break-word
      padding: 25px
    .line2
      @include roboto-regular
      color: $automation-centre-navigator-menu-links-green
      font-size: 36px
      line-height: 36px
      word-wrap: break-word
      margin: 0
    .line3 , .customMessage
      @include roboto-regular
      margin: 11px 0 0 0
      color: $automation-centre-aurecon-grey-primary
      font-size: 16px
      line-height: 24px
      letter-spacing: 0.5px
      word-wrap: break-word
    .customMessage
      width: clamp(200px, 100%, 740px)
      
@media screen and (max-width: $breakpoint--mobile)
  .container
    padding: 40px