@import './_all_vars.sass'

.metadataRow
  .metadataFieldRow
    padding-top: 8px

    .metadataFieldlabel
      @include roboto-medium
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.1px
      text-align: left
      display: inline-block

    .metadataPillsBox
      display: flex
      flex-wrap: wrap
      gap: 8px

    .metadataPill
      border-radius: 40px
      padding-bottom: 8px

      span
        @include roboto-regular
        font-size: 12px

      &.item
        background-color: $automation-centre-upload-automation-pill-originator
    
    .comboBox
      width: 100%
      &.loading
        i:global(.custom-icon)
          animation: spin-animation 1s infinite
          display: inline-block

    .errorMessage
      @include roboto-regular
      font-size: 12px
      line-height: 16px
      margin-left: auto
      color: $automation-centre-form-message-error
      margin-top: 8px
      width: 100%


    @keyframes spin-animation
      0%
        transform: rotate(0deg)
      100%
        transform: rotate(360deg)