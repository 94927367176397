@import './_all_vars.sass'
.layoutContainer
  display: flex
  flex-direction: column
  width: 100%
  justify-content: space-between

  .hero
    width: 100%
    align-items: flex-start
    justify-content: space-between
    flex-direction: column
    display: flex
  
  .filters
    background-color: $automation-centre-search-filter !important

  h2
    margin-top: 36px
    padding: 12px
    font-size: 22px
    text-align: left
    font-weight: 500
    display: flex
    font-family: Roboto, sans-serif
    line-height: 28px

  .buttonContainer
    display: flex
    justify-content: flex-end
    margin-top: 40px
    align-items: flex-start
    flex-direction: row
    margin-right: 36px

    button 
      border-radius: 20px
      background-color: #fff
      border: 1px solid #000
      padding: 8px, 16px, 8px, 12px
      font-size: 14px
      font-weight: 500
      height: 40px
      width: 85px
      line-height: 20px
      font-family: Roboto, sans-serif
      i
        font-family: Material Icons Outlined, sans-serif
        margin-right: 8px

  .sectionDefault
    display: grid !important
    grid-template-columns:  repeat(auto-fill, minmax(371px, 436px))
    grid-auto-columns: 1fr
    gap: 24px
    padding: 36px 16px 0 16px

  .section
    display: grid !important
    grid-template-columns:  repeat(auto-fit, minmax(min(100%, 371px), 1fr))
    grid-auto-columns: 1fr
    gap: 24px
    padding: 36px 16px 0 16px
  
  .noResultText
    @include roboto-regular
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.5px

  .loadingContainer
    display: flex
    justify-content: center
    align-items: center
    height: 50vh
  
  .sectionTitle
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: 40px
    margin-left: auto
    padding-top: 24px

    .pageButtonsContainer
      display: inline-flex
      gap: 8px
      .pageButtons
        background: white
        color: $automation-centre-aurecon-grey-primary
        border: 1px solid $automation-centre-aurecon-grey-primary !important
        &.active
          border: 1px solid $automation-centre-aurecon-grey-secondary
          background: $automation-centre-aurecon-grey-secondary !important
        i
          font-size: 36px !important

    .pageReference
      margin-right: 12px
      p
        @include roboto-medium
        font-size: 14px
        line-height: 20px
      input
        width: 40px
        height: 40px
        margin-left: 8px
        margin-right: 8px
        text-align: center
  
  .metadataSection
    width: 100%
    height: auto !important
    background-color: $automation-centre-upload-automation-column-background
    border-radius: 8px
    display: flex
    flex-direction: row
    align-items: center
    padding: auto
    margin: 24px auto
    flex-wrap: nowrap

    .dropdownContainer
      min-width: 400px

      .metadataDropDownBox
        padding: 16px
        margin: auto
        min-width: 150px

        div:global(.dropdown-options)
          min-width: 200px
          height: auto
          padding: 5px 0px

    .labelButtonContainer
      justify-content: center
      align-items: center
      min-width: 60px
      max-width: 120px
      margin: auto

      .filterLabel
        padding: auto
        align-items: center
        justify-content: center
    
    p
      @include roboto-regular
      font-size: 14px
      line-height: 20px
      display: flex
      align-items: center
      flex: 1
      
    .pill
      margin-left: auto
      margin-right: 5px
    
    button
      @include roboto-medium
      color: $automation-centre-aurecon-grey-primary
      font-size: 14px
      line-height: 20px

.searchLoader
  display: flex
  justify-content: center
  height: 100%