@import ./_all_vars.sass

.featuredCard
    width: 100%
    height: 100%
    padding: 16px
    background: $automation-centre-featured-card-background
    border-radius: 8px
    overflow: hidden
    justify-content: flex-end
    align-items: flex-start
    gap: 16
    display: inline-flex
    border: 1px solid $automation-centre-featured-card-background
    

    .details
        flex: 1 1 0
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        gap: 16
        display: inline-flex
        cursor: pointer

        .featuredTag
            @include roboto-medium
            color: $automation-centre-featured-text-tag-color
            font-size: 12px
            line-height: 16px
            letter-spacing: 0.5px
            word-wrap: break-word
            margin-bottom: 16px

        
        .title
            @include roboto-medium
            font-size: 22px
            align-self: stretch
            color: $automation-centre-featured-card-title-color
            line-height: 28px
            word-wrap: break-word
            margin-bottom: 4px
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-line-clamp: 1
            -webkit-box-orient: vertical

        .metadata
            @include roboto-medium
            color: $automation-centre-featured-card-metadata-color
            display: inline-flex
            justify-content: flex-start
            align-items: center 
            gap: 16px
            font-size: 14px
            line-height: 16px
            letter-spacing: 0.50px
            word-wrap: break
        
        .description
            @include roboto-regular
            font-size: 14
            line-height: 20px
            letter-spacing: 0.25px
            margin: 16px 0

            width: 95%

            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical
    
    .thumbnail
        border-radius: 8
        justify-content: center
        align-items: center
        display: flex
        align-self: center
        width: 176px
        height: 176px

        loader
            margin: auto
        
        img
            width: 175px
            height: 175px
            border-radius: 8px
    
    &:hover
        border: 1px solid $automation-centre-featured-card-hover-border-color
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)