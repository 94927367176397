@import './_all_vars.sass'

.container
  display: flex
  flex-direction: column
  padding: 110px 106px
  height: 100dvh
  width: 100dvw
  background-color: none !important

  .logoBackground
    width: clamp(100px, 100%, 251px)
    position: absolute
    bottom: 77px
    left: calc(100vw - 43px - 251px)
    z-index: 1
  
  .aureconLogo
    img
      height: 24px
     
  .contents
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    background-color: none !important
    img
      width: clamp(100px, 100%, 383px) !important
      margin-bottom: 11px
    .errorCode
      @include meta-pro-regular
      color: $automation-centre-aurecon-grey-primary
      font-size: 100px
      line-height: 36px
      word-wrap: break-word
      margin: 48px 0 24px 0
    .errorLabel
      @include meta-pro-regular
      color: $automation-centre-aurecon-green-primary
      font-size: 36px
      line-height: 36px
      word-wrap: break-word
    .line1, .line2
      @include roboto-regular
      color: $automation-centre-aurecon-grey-primary
      font-size: 16px
      line-height: 16px
      word-wrap: break-word
      margin: 12px 0 0 0  
    
    .homeBtn
      @include atc-btn-primary-green
      padding: 8px 16px
      border-radius: 20px
      margin-top: 24px
      font-family
      i
        font-family: 'Material Icons Outlined'
        font-weight: 400
      
@media screen and (max-width: $breakpoint--mobile)
  .container
    padding: 40px