@import './_all_vars.sass'


.loaderContainer
    display: flex
    justify-content: center
    align-items: center
    height: 500px

.settingsContainer
    display: flex
    flex-direction: column
    .featuredItemContainer
        width: 100%
        height: 100%
    .topControlsContainer
        width: 100%
        display: flex
        .rightControlsContainer
            display: flex
            width: 25%
        .controlsContainer
            display: flex
            width: 75%
            .controls
                border-radius: 20px !important
                padding: 16px 16px !important
                display: flex
                align-items: center !important
                margin: 10px 5px
            .changeOrderButton
                @include atc-btn-secondary
            .publishButton
                @include atc-btn-primary-green
            .lastPublished
                margin: auto 10px
                @include roboto-medium
                font-size: 14px
                line-height: 20px
                letter-spacing: 0.1px
                text-align: left
                display: inline-block
                color: $automation-centre-aurecon-grey-light
    .actionsContainer
        display: flex
        align-items: right
        justify-content: flex-end
        .actionsIcon
            background: $automation-centre-aurecon-grey-secondary
            border-radius: 50%
            color: white
            cursor: pointer
            padding: 15px 10px
            margin: 10px
            height: fit-content
            width: fit-content
    .featuredAutomationsContainer
        width: 100%
        .topFeaturedContainer
            display: flex

        