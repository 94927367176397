@import './_all_vars.sass'

.layoutContainer
  display: flex
  flex-direction: column
  width: 100%
  justify-content: space-between

  .hero
    width: 100%
    align-items: flex-start
    justify-content: space-between
    flex-direction: column
    display: flex

    .tabs 
      display: flex
      justify-content: space-between
      width: 100%
      margin-top: 24px

    .tab 
      padding: 10px 20px
      cursor: pointer
      border: none
      width: 100%
      text-align: center
      border-bottom: 2px solid #ccc

    .tab.active 
      border-bottom: 2px solid $automation-centre-view-btn-color

  h2
    @include roboto-regular
    margin-top: 36px
    padding: 12px
    font-size: 22px
    text-align: left
    display: flex
    line-height: 28px

  .table
    margin-top: 44px
    width: 99% !important
    table
      thead
        tr
          border-bottom: none !important
          height: 56px    
          td
            background-color: $automation-centre-upload-automation-column-background !important
      tbody
        tr
          &:hover
            td
              background-color: $automation-centre-text-menu-background-hover !important
  
  .searchfilterContainer
    flex: 1
    display: flex
    justify-content: flex-end
    margin-top: 40px
    align-items: flex-start
    flex-direction: row

    .searchBox 
      width: 100%
      margin-bottom: -10px
      position: relative
      

      .searchInput
        border-radius: 4px
        border: 1px solid $automation-centre-search-input-border-color
        padding: 0 0 0 32px
        background-color: $automation-centre-search-input-background

        &:focus-within
          border: 1px solid $automation-centre-search-input-active-border-color
        
        input
          border-bottom: none
    .icon
      position: relative
      left: 10px
      bottom: 32px
    
    .buttonContainer
      button 
        @include roboto-medium
        border-radius: 20px
        background-color: $automation-centre-aurecon-grey-secondary
        border: 1px solid #000
        padding: 8px, 16px, 8px, 12px
        font-size: 14px
        height: 40px
        width: 40px
        line-height: 20px
        display: flex
        align-items: center
        justify-content: center
        i
          color: #FFFFFF
          font-family: Material Icons Outlined, sans-serif
          font-size: 24px
          margin-left: 12px


      
      

    
  
