@import './_all_vars.sass'

.loginPage
  width: 100vw
  height: 100vh
  background-image: url("../assets/login_page_bg_v2.jpg")
  background-image: linear-gradient(90deg, rgba(38, 50, 56, 0.8) 26%, rgba(38, 50, 56, 0) 100%), url("../assets/login_page_bg_v2.jpg")
  background-position: center
  background-size: cover
  color: white
  font-family: Arial, Helvetica, sans-serif

  .loginContainer
    position: relative
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100vh
    margin: 0 32px 0 32px
    overflow: auto

    @media screen and (min-width: 576px)
      margin: 0 0 0 32px

    @media screen and (min-width: 768px)
      margin: 0 0 0 72px

    @media screen and (min-width: 992px)
      margin: 0 0 0 144px

  .header
    padding-top: 72px

  .content
    width: 100%
    .welcome
      @include roboto-regular
      margin: 0
      font-size: 24px
      line-height: 36px
      word-wrap: break-word
    .appName
      @include roboto-regular
      font-size: 52px
      margin: 0
    .paragraph
      @include roboto-regular
      font-size: 16px
      width: 60%
      overflow-wrap: break-word
      line-height: 24px
      letter-spacing: 0.5px

    button
      @include roboto-medium
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.10px
      margin-right: 20px 
      margin-top: 154px
      border-radius: 20px !important
      padding: 10px 15px 
      font-size: 12px !important


    @media screen and (min-width: 576px)
      width: 500px

    .logo
      height: 40px
      width: auto

  .footer
    font-size: 14px
    padding-bottom: 105px
    position: relative
    flex: 0

    .links
      button
        margin-right: 40px
        padding: 0
        span
          font-size: 14px
          @include roboto-medium
          line-height: 20px
          letter-spacing: 0.10px
          word-wrap: break-word

    .version
      margin-top: 40px
      color: $automation-centre-aurecon-silver
      font-size: 14px
      @include roboto-medium
      line-height: 20px
      letter-spacing: 0.10px
      word-wrap: break-word

.consentRequired
  display: flex
  align-content: center
  justify-content: center
  flex-direction: column
  height: 100dvh
      
