@import './_all_vars.sass'

.layoutContainer
  margin: 0
  display: flex
  flex-direction: column
  width: 100%
  justify-content: space-between
  .divider
    border-bottom: 1px solid $automation-centre-divider-color
    margin: 40px 0
  .header
    .pageTitlte
        margin: 40px 0 40px 0
        text-align: left
        font-size: 28px
        line-height: 36px
  .sinceLaunchedContainer
    display: flex
    flex-direction: column
    gap: 8px
    align-items: flex-start
    .sinceLaunchedTitle
      @include roboto-medium
      font-size: 22px
    .sinceLaunchedContent
      display: flex
      flex-direction: row
      justify-content: space-between
      gap: 40px
      width: 100%
      .sinceLaunchedItem
        width: 100%
        padding: 24px
        border-radius: 8px
        .sinceLaunchedItemTitle
          @include roboto-medium
          font-size: 16px
          line-height: 24px
          letter-spacing: 0.1px
          color: $automation-centre-aurecon-granite-grey
        .sinceLaunchedItemValue
          @include roboto-medium
          font-size: 45px
          line-height: 52px
          letter-spacing: 0.1px
          color: $automation-centre-aurecon-chinese-black
          margin: 0
          text-align: right
      .totalSubmission
        background: $automation-centre-aurecon-peach !important

      .totalDownloads
        background: $automation-centre-aurecon-yellow--60 !important

      .uniqueUsers
        background: $automation-centre-aurecon-green-secondary--60 !important
  .appInsightsContainer
    width: 100%
    padding: 0
    border-radius: 8px
    .appInsightsHeader
      display: inline-flex
      width: 100%
      height: 52px
      flex-direction: row
      align-items: center
      line-height: 28px
      margin-bottom: 24px
      justify-content: space-between
      .appInsightsTitle
        .title
          @include roboto-medium
          font-size: 22px
          line-height: 28px
          color: $automation-centre-aurecon-grey-secondary
      .actionContainer
        display: inline-flex
        flex-direction: row
        justify-content: space-between
        gap: 16px
        .dateRange
          display: inline-flex
          flex-direction: row
          justify-content: space-between
          align-items: center
          gap: 16px
          .dateRangeTitle
            @include roboto-medium
            font-size: 12px
            color: $automation-centre-aurecon-raven-black
            white-space: nowrap
          .dateRangeInput
            div:global(.react-daterange-picker)
              border: 1px solid $automation-centre-aurecon-grey-primary !important
              padding: 10px
              border-radius: 4px
              button
                i
                  color: $automation-centre-aurecon-green-primary !important
        .downloadButton
          justify-self: flex-end !important
          align-self: flex-end
          @include atc-btn-primary-grey
          i
            font-family: 'Material Icons Outlined'
        .customDownloadButton
          background: $automation-centre-aurecon-grey-secondary
          border-radius: 50%
          color: white
          cursor: pointer
          padding: 8px
          margin: 10px
          height: fit-content
          width: fit-content
          i
            font-family: 'Material Icons Outlined'
            margin: auto

    .appInsightsContents
      display: flex
      height: 587px
      gap: 24px
      justify-content: space-between
      .appInsightsGraph
        width: 33.33%
        border: 1px solid $automation-centre-aurecon-silver
        border-radius: 8px
        padding: 24px
        
        .loadingGraph
          display: flex
          justify-content: center
          align-items: center
          height: 100%
          width: 100%

        .totalCount
          @include roboto-medium
          font-size: 22px
          line-height: 28px
          text-align: left
        div:global(.recharts-legend-wrapper)
          span:global(.recharts-legend-item-text)
              @include roboto-regular
              font-size: 14px
              line-height: 20px
              letter-spacing: 0.25px
              text-align: left
              color: black !important
      .appInsightsTable
        position: relative
        width: 66.66%
        border: 1px solid $automation-centre-aurecon-silver
        border-radius: 8px
        padding: 24px
        .noAvailableData
          @include roboto-medium
          text-align: center !important
          border: none !important
          height: 450px
        table
          thead
              tr
                  border: none !important
              td
                  @include roboto-medium
                  background-color: none !important
                  padding: 12px !important
                  div
                      display: inline-flex
                      align-items: center
                  &:hover
                      background-color: $automation-centre-aurecon-grey-primary !important
                      color: $automation-centre-background-light-primary !important
                  &:active
                      background-color: $automation-centre-aurecon-grey-secondary !important
                      color: white !important
          tbody
            tr
              td
                border-left: none !important
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                max-width: 0
                max-width: 15ch
                padding: 8px !important
        div:global(#custom-pagination)
          margin-top: 24px !important
          position: absolute
          bottom: 0
          right: 24px

        .applicationTag
          @include roboto-regular
          font-size: 12px !important
          line-height: 16px
          letter-spacing: 0.40px
          text-wrap: nowrap
          color: $automation-centre-aurecon-grey-primary
          border-radius: 40px !important
          padding: 4px 12px !important
          margin: 4px 8px 4px 0
          cursor: pointer
        .automationCentre
          background-color: $automation-centre-tag-color
        .scriptLibrary
          background-color: $script-library-tag-color
  .uniqueAndPublishedContainer
    display: flex
    flex-direction: row
    gap: 40px
    width: 100%
    margin-bottom: 40px
    .uniqueUsersContainer
      width: 60%
      height: 571px
      border: 1px solid $automation-centre-aurecon-silver
      padding: 40px 24px
      border-radius: 8px
      .uniqueGraphContainer
        margin-top: 40px
        width: 100%
        height: 100%
        display: flex
        flex-direction: row
        justify-content: space-between
        position: relative
        .loaderContainer
          position: absolute
          background: rgba(255, 255, 255, 0.8)
          z-index: 1
          display: flex
          justify-content: center
          align-items: center
          width: 100%
          height: 320px 
        .noData
          display: flex
          justify-content: center
          align-items: center
          height: 289px 
          width: 100%
          @include roboto-regular
          font-size: 14px
          line-height: 20px
          text-align: center
          color: $automation-centre-aurecon-raven-black
        .uniqueUsersPieGraph
          width: 100% !important
          height: 289px !important  
      
      .uniqueUsersHeader
        display: inline-flex
        width: 100%
        flex-direction: row
        justify-content: space-between
        align-items: center
        line-height: 28px
        margin-bottom: 24px
        .uniqueUserstitle
          @include roboto-medium
          font-size: 22px
          color: $automation-centre-aurecon-grey-secondary
        .downloadButton
          @include atc-btn-primary-grey
          i
            font-family: 'Material Icons Outlined'

    .publishedAutomationsContainer
      width: 40%
      height: 571px
      border: 1px solid $automation-centre-aurecon-silver
      padding: 40px 24px
      border-radius: 8px
      .publishedAutomationsHeader
        display: inline-flex
        width: 100%
        flex-direction: row
        justify-content: space-between
        align-items: center
        line-height: 28px
        margin-bottom: 24px
        .publishedAutomationstitle
          @include roboto-medium
          font-size: 22px
          color: $automation-centre-aurecon-grey-secondary
      .publishedAutomationsGraphContainer
        margin-top: 40px
        width: 100%
        height: 70%
        display: flex
        flex-direction: row
        justify-content: space-between
        .loaderContainer
          display: flex
          justify-content: center
          align-items: center
          width: 100%
          height: 320px 

.downloadButton
  @include atc-btn-primary-grey
  i
    font-family: 'Material Icons Outlined'

.uniqueAndPublishedActionContainer
  display: inline-flex
  flex-direction: row
  justify-content: space-between
  gap: 16px
  width: 100%
  .sinceLaunchedContainer
    .sinceLaunchedCount
      @include roboto-medium
      font-size: 22px 
      line-height: 28px
      text-align: left
      margin-top: 7px
    .sinceLaunchedLabel
      @include roboto-medium
      font-size: 12px
      line-height: 16px
      letter-spacing: 0.5px
      text-align: left
      color: $automation-centre-aurecon-granite-grey

  .dateRange
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: flex-start
    gap: 8px
    .dateRangeTitle
      @include roboto-medium
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.1px
      color: $automation-centre-aurecon-raven-black
      white-space: nowrap
    .dateRangeInput
      div:global(.react-daterange-picker)
        border: 1px solid $automation-centre-aurecon-grey-primary !important
        padding: 10px
        border-radius: 4px
        button
          i
            color: $automation-centre-aurecon-green-primary !important
    .dateRangeDropdown
      width: 300px
      min-width: 100px
.legendList
  padding: 0
  width: 253px
  height: 289px
  display: flex
  flex-direction: column
  .legend
    width: 100%
    display: inline-flex !important
    align-items: center
    justify-content: space-between
    .value
      display: inline-flex
      align-items: center
      .legendCountry
        @include roboto-regular
        font-size: 14px
        line-height: 20px
        letter-spacing: 0.25px
        text-align: left
        color: $automation-centre-aurecon-raven-black
    .legendCount
      @include roboto-regular
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.25px
      text-align: left
      color: $automation-centre-aurecon-raven-black
      justify-self: flex-end !important
.legendAccordion
  display: flex
  align-items: flex-end !important
  flex-direction: column
  .row
    display: inline-flex !important
    width: 83%
    justify-content: space-between
    align-items: center
    .value, .count
      @include roboto-regular
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.25px
      text-align: left
      color: $automation-centre-aurecon-raven-black
      justify-self: flex-end !important


@media screen and (max-width: $breakpoint--tablet + 1)
  .appInsightsContents
    display: flex
    flex-direction: column
    height: 100% !important
    .appInsightsGraph
      width: 100% !important
    
    .appInsightsTable
      width: 100% !important
      div:global(.table-main-wrapper)
        overflow-x: auto !important
      div:global(#custom-pagination)
        margin-top: 24px !important
        position: relative !important

  .uniqueAndPublishedContainer
    display: flex
    flex-direction: column !important
    gap: 40px
    .uniqueUsersContainer
      width: 100% !important
    .publishedAutomationsContainer
      width: 100% !important