@import './_all_vars.sass'

.divider
    border-bottom: 1px solid $automation-centre-divider-color!important
    margin: 24px 0


.cover
    display: flex
    flex-flow: column nowrap
    padding: 24px 40px
    background-position: center
    background-repeat: no-repeat
    background-size: cover
    width: 30%

    .backButton
        color: white !important
        border-color: white !important
         
        &:hover
            background-color: $automation-centre-aurecon-grey-primary !important
            border: 1px solid $automation-centre-aurecon-grey-primary !important
        
        &:active
            background-color: $automation-centre-aurecon-grey-secondary !important
            border: 1px solid $automation-centre-aurecon-grey-secondary !important
    
    .titleContainer
        margin-top: 24px
        border-radius: 4px
        padding: 24px
        background-color: $automation-centre-information-title-background
        width: 100%
        color: white

        .upperSection
            display: flex
            justify-content: space-between
            align-items: center
            .title
                font-size: 28px
            .downloadBtn
                color: $automation-centre-combobox-border
                font-weight: 200!important
                padding: 8px
                border-radius: 25px
                height: 40px
                width: 40px
                background-color: $automation-centre-view-btn-color
                border-color: $automation-centre-view-btn-color
                &:hover
                    cursor: pointer
                    background-color: $automation-centre-combobox-border
                    color: white
                
        .tags
            margin-top: 24px

.details
    width: 70%
    .copyContainer
        display: flex
        width: 100%
        justify-content: flex-end
        align-items: center
        
    .copyIcon
        cursor: pointer
        margin-left:8px


.displayPill
    @include roboto-regular
    font-size: 12px !important
    line-height: 16px
    letter-spacing: 0.40px
    text-wrap: nowrap
    border-radius: 40px !important
    padding: 4px 12px !important
    margin: 4px 8px 4px 0
    background-color: $automation-centre-aurecon-grey-secondary 
    color: $automation-centre-background-light-primary 


.authorButtons
    display: flex
    justify-content: flex-end
    button
        @include roboto-medium
        background-color: white !important
        margin: 0px 0 0 16px 
        border-radius: 20px
        padding: 8px 16px
        font-size: 14px
        line-height: 20px
        letter-spacing: 0.10px
        border: 1px solid $automation-centre-upload-automation-input-border
        
        &:hover
            background-color: $automation-centre-aurecon-grey-primary !important
            color: white !important
        &:active
            background-color: $automation-centre-aurecon-grey-secondary !important
            color: white !important

    .close, .edit
        border: 1px solid $automation-centre-upload-automation-input-border !important
        &:disabled
            background-color: white !important
            &:hover
                color: black !important
