@import './_all_vars.sass'
  
@mixin placeholder 
  &::-webkit-input-placeholder
    color: #000

  &::-ms-input-placeholder
    color: #000

  &::-moz-placeholder
    color: #000

  &::placeholder
    color: #000


// ------ Loging page buttons START -------

.login
  button.is-primary
    &.is-white
      color: black
      border-color: white
      background-color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #555
        background-color: #555 !important


  button.is-secondary
    &.is-white
      color: white
      border-color: white
      background-color: transparent

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #999
        background-color: #999 !important

  button.is-text
    &.is-white
      color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: #999 !important
        border-color: transparent

// ------ Loging page buttons END -------

.pagination-wrapper
  .pagination-list
    button.pagination-chevron
      i.material-icons.chevron-icon
        color: $automation-centre-main-light

        &:hover
          background-color: $automation-centre-main-dark
          color: white

    button.pagination-item
      &.is-active
        color: white

      color: $automation-centre-main-light

      &:hover
        background-color: $automation-centre-main-dark
        color: white

.field-wrapper 
  > label 
    .input-field 
      .search-icon
        color: $automation-centre-main-light

      .clear-icon
        color: $automation-centre-aurecon-primary-green

      .number-up-icon
        color: $automation-centre-aurecon-primary-green

      .number-down-icon
        color: $automation-centre-aurecon-primary-green

.dropdown-theme-light
  --dropdown-primary-colour-main: #{$automation-centre-dropdown-primary}
  --dropdown-borders: #{$automation-centre-dropdown-border}
  --dropdown-open-background-colour: #{$automation-centre-dropdown-background}
  --dropdown-icon-colour: #{$automation-centre-dropdown-icon}
  --dropdown-icon-colour-hover: #{$automation-centre-dropdown-icon}
  --dropdown-primary-text-colour: #{$automation-centre-dropdown-text}
  --dropdown-primary-text-colour-hover: #{$automation-centre-dropdown-background}
  --dropdown-secondary-text-colour: #{$automation-centre-dropdown-text}
  --dropdown-item-background-colour: #{$automation-centre-dropdown-background}
  --dropdown-item-list-background-colour: #{$automation-centre-dropdown-background}
  --dropdown-border-disabled: #{$automation-centre-dropdown-border-disabled}
  --dropdown-text-disabled: #{$automation-centre-dropdown-text-disabled}
  
.dropdown-wrapper 
  .dropdown-content
    div.dropdown-selected
      border-radius: 4px
      border: 1px solid var(--dropdown-borders)
      background: var(--dropdown-open-background-colour)
      font-family: Roboto
      font-size: 14px
      font-weight: 400

      i
        color: $automation-centre-dropdown-icon !important

      &.is-open
        background: var(--dropdown-primary-colour-main)
       
        i
          color: $automation-centre-dropdown-icon !important
          transform: rotate(180deg)

      &.is-disabled
        color: var(--dropdown-text-disabled)
        border-color: var(--dropdown-border-disabled)
        cursor: default !important
        i
          color: var(--dropdown-border-disabled) !important
        &:hover
          color: var(--dropdown-text-disabled)
          border-color: var(--dropdown-text-disabled)
          cursor: default !important

    div.dropdown-options
      margin-top: 8px
      
    .dropdown-options
      border-radius: 4px
      border: 1px solid var(--dropdown-borders)
      max-height: 202px
      overflow: auto

      .dropdown-item
        &:hover
          background: var(--dropdown-primary-colour-main)
          color: var(--dropdown-open-background-colour)
          .checkbox
             background: white
             i
              color: var(--dropdown-primary-colour-main) !important

        span
          font-family: Roboto
          font-size: 14px
          font-weight: 400


.combobox-theme-light
  --combobox-primary-colour-main: #{$automation-centre-combobox-primary} 
  --combobox-borders: #{$automation-centre-combobox-border}
  --combobox-open-background-colour: #{$automation-centre-combobox-background}
  --combobox-icon-colour: #{$automation-centre-combobox-icon}
  --combobox-icon-colour-hover: #{$automation-centre-combobox-icon}
  --combobox-primary-text-colour: #{$automation-centre-combobox-text}
  --combobox-primary-text-colour-hover: #{$automation-centre-combobox-background}
  --combobox-secondary-text-colour: #{$automation-centre-combobox-text}
  --combobox-item-background-colour: #{$automation-centre-combobox-background}
  --combobox-item-list-background-colour: #{$automation-centre-combobox-background}

// -- This should not be affecting the combobox. Will report to Jakub.   
.dropdown-input-theme-light
  --dropdown-input-primary-colour-main: #{$automation-centre-combobox-primary}
  
.combobox-wrapper
  .combobox-content
    .field-wrapper
      &.is-disabled
        opacity: 0.5
      .combobox-selected
        margin-top: 8px
        background: var(--dropdown-open-background-colour)

      i
        color: $automation-centre-combobox-icon

      .dropdown-icon
        color: $automation-centre-combobox-icon !important

      .input-field

      
        .custom-icon 
          color: black !important
          margin: 12px 4px !important

        .dropdown-icon
          color: $automation-centre-combobox-icon !important
        
        input
          color: black
          background: var(--combobox-open-background-colour)
          border: 1px solid var(--combobox-borders)
          border-radius: 4px
          @include placeholder
          
        &.is-open
          input
            border: 1px solid $automation-centre-active-input-border-green
            color: black
          .custom-icon
            color: black !important
          .dropdown-icon, .clear-icon 
            color: $automation-centre-combobox-icon !important

        input
          &:focus, &:active
            background-color: $automation-centre-combobox-background
      
        &.is-disabled
          i
            color: var(--dropdown-border-disabled) !important
          input           
            &:hover
              @include placeholder
              background: var(--combobox-open-background-colour)
              border: 1px solid var(--combobox-borders)
              border-radius: 4px
              cursor: default !important

    .options-wrapper
      border-radius: 4px
      background: var(--combobox-open-background-colour)
      margin-top: 8px 

      ul.options
        border-radius: 4px
        background-color: var(--combobox-open-background-colour)

        li
          font-family: Roboto
          font-size: 14px
          font-weight: 400


        & li:hover 
          background-color: var(--combobox-primary-colour-main)
          color: var(--combobox-open-background-colour)


.automation-centre-icon-button
  button.button
    &.is-icon
      color: $automation-centre-main-light
      background: transparent

      &:disabled
        color: $automation-centre-main-light
        background: transparent

        &:hover
          color: $automation-centre-main-light

      &:focus,
      &:hover
        color: white

.toast-container 
  .top-right
    top: 84px
    right: 40px

@media screen and (min-width: 1200px)
  .report-list-container
    .container
      min-width: 1140px

.tooltip-wrapper
  .tooltip-popup
    max-width: 600px !important
    
.combobox-theme-light
  --combobox-primary-colour-main: #{$automation-centre-combobox-primary} 

.checkbox-theme-light
  --checkbox-primary-colour-main: #{$automation-centre-combobox-checkbox}
  --checkbox-primary-colour-main-border: #{$automation-centre-combobox-checkbox}
