@import './_all_vars.sass'

.layoutContainer
  display: flex
  flex-direction: column
  width: 100%
  justify-content: space-between

  .hero
    width: 100%
    align-items: flex-start
    justify-content: space-between
    flex-direction: column
    display: flex

    .tabs 
      display: flex
      justify-content: space-between
      width: 100%
      margin-top: 24px

    .tab 
      padding: 10px 20px
      cursor: pointer
      border: none
      width: 100%
      text-align: center
      border-bottom: 2px solid #ccc

    .tab.active 
      border-bottom: 2px solid $automation-centre-view-btn-color

  h2
    @include roboto-regular
    margin-top: 36px
    padding: 12px
    font-size: 22px
    text-align: left
    display: flex
    line-height: 28px

  .table
    margin-top: 44px
    width: 99% !important
    table
      thead
        tr
          border-bottom: none !important
          height: 56px    
          td
            background-color: $automation-centre-upload-automation-column-background !important
      tbody
        tr
          &:hover
            td
              background-color: $automation-centre-text-menu-background-hover !important

    button 
      border-radius: 16px
      background-color: $automation-centre-aurecon-grey-secondary !important
      font-size: 8px
      font-weight: 500
      height: 24px
      width: 24px
      line-height: 20px
      display: flex
      margin-left: 12px
      align-items: center
      justify-content: center
      &:hover
        background-color: $automation-centre-aurecon-grey-primary !important
      i
        color: #FFFFFF
        font-size: 16px
        font-family: Material Icons Outlined, sans-serif
        margin-left: 12px

    .pill
      background-color: $automation-centre-upload-automation-pill-source !important
      height: 24px
      width: 90px
      justify-content: center
      cursor: pointer

  .sectionPage
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: 40px
    margin-top: 24px
    margin-right: 12px
    margin-left: auto

    .pageButtonsContainer
        display: inline-flex
        gap: 8px
        .pageButtons
          background: white
          color: $automation-centre-aurecon-grey-primary
          border: 1px solid $automation-centre-aurecon-grey-primary !important
          &.active
            border: 1px solid $automation-centre-aurecon-grey-secondary
            background: $automation-centre-aurecon-grey-secondary !important
          i
            font-size: 36px !important

    .pageReference
      margin-right: 12px
      p
        @include roboto-medium
        font-size: 14px
        line-height: 20px
      input
        width: 40px
        height: 40px
        text-align: center
  
  .searchfilterContainer
    flex: 1
    display: flex
    justify-content: flex-end
    margin-top: 40px
    align-items: flex-start
    flex-direction: row

    .searchBox 
      width: 100%
      margin-bottom: -10px
      position: relative
      

      .searchInput
        border-radius: 4px
        border: 1px solid $automation-centre-search-input-border-color
        padding: 0 0 0 32px
        background-color: $automation-centre-search-input-background

        &:focus-within
          border: 1px solid $automation-centre-search-input-active-border-color
        
        input
          border-bottom: none
    .icon
      position: relative
      left: 10px
      bottom: 32px
    
    .buttonContainer
      button 
        @include roboto-medium
        border-radius: 20px
        background-color: $automation-centre-aurecon-grey-secondary
        border: 1px solid #000
        padding: 8px, 16px, 8px, 12px
        font-size: 14px
        height: 40px
        width: 40px
        line-height: 20px
        display: flex
        align-items: center
        justify-content: center
        i
          color: #FFFFFF
          font-family: Material Icons Outlined, sans-serif
          font-size: 24px
          margin-left: 12px
        
  .metadataSection
    width: 99%
    background-color: #FFFFFF
    border-radius: 8px
    height: 88px
    display: flex
    flex-direction: row
    align-items: center
    padding-right: 32px
    padding-left: 24px
    margin-right:32px
    margin-top: 24px
    flex-wrap: nowrap

    .metadataDropDownBox
      padding: 16px
      width: 250px
      display: flex
      align-items: center
      justify-content: space-between
    
    p
      @include roboto-regular
      font-size: 14px
      line-height: 20px
      display: flex
      align-items: center
      flex: 1

    .pill
      margin-left: auto
      margin-right: 5px
      @include roboto-regular
      font-size: 12px
      line-height: 16px
      letter-spacing: 0.4px
      text-align: left

    button
      color: $automation-centre-aurecon-grey-primary
      font-weight: 500
      font-size: 14px
      line-height: 20px

.approvalStatus
  span
    font-family: "Roboto", sans-serif !important
    font-weight: 400
    font-size: 12px !important
    line-height: 16px
    letter-spacing: 0.4px
    text-align: left  

      
      

    
  
