@import './_all_vars.sass'

.modalBody
  width: 857px
  border: 1px solid $automation-centre-termsconditionsmodal-border-color

  .tcContainer
    display: flex
    justify-content: space-between
    padding: 24px
    border-bottom: 1px solid $automation-centre-termsconditionsmodal-divider-color
    background-color: $automation-centre-background-light-primary

    .tcText
      display: flex
      text-align: left

      p
        @include roboto-medium
        line-height: 20px
        letter-spacing: 0.25px

      a 
        @include roboto-medium
        line-height: 20px
        letter-spacing: 0.25px

    .tcButtonBox
      display: flex
      justify-content: flex-end
      align-items: center
      gap: 8px
      padding-right: 24px

      .tcDeclineButton
        height: 40px
        width: 80px
        border-radius: 20px
        border: 1px solid $automation-centre-termsconditionsmodal-declinebutton-color
        background-color: $automation-centre-background-light-primary

        &:hover:enabled,
        &:focus:enabled 
          span
            color: white 
            
        span
          @include roboto-medium
          font-size: 14px
          line-height: 20px
          letter-spacing: 0.1px
          color: $automation-centre-termsconditionsmodal-declinebutton-color

      .tcAcceptButton
        height: 40px
        width: 80px
        border-radius: 20px
        border: 1px solid $automation-centre-termsconditionsmodal-acceptbutton-color
        background-color: $automation-centre-termsconditionsmodal-acceptbutton-color
        color: $automation-centre-background-light-primary

        span
          @include roboto-medium
          font-size: 14px
          line-height: 20px
          letter-spacing: 0.1px


.staticContent
  font-family: Arial, Helvetica, sans-serif
  padding: 80px 170px 80px 170px
  width: auto

  h1
    text-align: center

    sup
      font-size:15px

  sup
      font-size:12px

  .backToTopBtn
    text-align: right

  .list
    font-size: 16px

    > li
      font-weight: bold
      margin-top: 3em
  ul
    margin-bottom: 2em

    li
      line-height: 1.5em
      margin-bottom: 1em

  .tableList
    li
      font-family: Arial, Helvetica, sans-serif
      font-size: 14px

  .versionHistoryTable
    margin-top: 16px