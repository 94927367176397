@import './_all_vars.sass'

.menu
  background-image: url("../assets/menu_bg.svg")
  background-size: cover
  background-color: $automation-centre-menu-background
  border-radius: 0
  margin: 0
  padding: 32px 0
  width: $automation-centre-menu-width
  position: relative
  display: flex
  flex-direction: column
  z-index: 100
  overflow-y: auto 

  .logoButton
    border: none
    display: flex
    justify-content: center
    margin: 10px 0px

  .automationCenterLogo
    margin: auto
    width: $automation-centre-menu-logo-width
    cursor: pointer
    display: flex
    justify-content: center

  &.collapsed
    .automationCenterLogo
      min-width: $automation-centre-menu-logo-width-small
      padding: 0 6px
      width: $automation-centre-menu-logo-width-small
    .menuItem
      padding-left: 12px

    .menuFooter
      .legalLinks
        display: flex
        flex-direction: column
        justify-content: center
        text-align: center

        .link
          margin-top: 5px

  .menuItem
    position: relative
    flex: 0
    width: 90%
    color: $automation-centre-text-menu
    font-size: 14px
    padding: 16px
    margin: 2px auto
    cursor: pointer
    font-weight: 400
    line-height: 20px

    &.active
      cursor: pointer
      background-color: $automation-centre-text-menu-background-active
      span
        font-weight: 500
        color: black

    img
      vertical-align: sub
      height: 20px
      padding-left: 4px

    i
      top: -2px

    &.disabled
      opacity: 0.5

    &:hover
      cursor: pointer
      background-color: $automation-centre-text-menu-background-hover

    .approvalsPill
      @include roboto-regular
      position: absolute
      right: 8px
      top: 12px
      font-size: 12px !important
      line-height: 16px
      letter-spacing: 0.40px
      text-wrap: nowrap
      border-radius: 40px !important
      padding: 4px 12px !important
      margin: 4px 8px 4px 0
      background-color: $automation-centre-navigator-menu-pill-color
      color: $automation-centre-text-menu
      .loader
        >div
          width: 14px
          padding-top: 4px

  .menuFooter
    flex: 1
    align-content: flex-end
   
    .contactUsBtnContainer
      display: flex
      justify-content: center
      .contactUsBtn
        background-color: rgba(255, 255, 255, 0.2) !important
        @include atc-btn-secondary
        @include roboto-medium
       
        height: 40px
        border-radius: 20px
        padding: 8px 16px 8px 12px
        line-height: 20px
        letter-spacing: 0.1px
        font-size: 14px
        cursor: pointer

    .menuVersion
      @mixin roboto-regular
      font-size: 12px
      text-align: center
      margin: 12px
      color: $automation-centre-text-menu-version
      line-height: 16px
      letter-spacing: 0.4px

    a
      font-size: 12px

  .legalLinks
    display: flex
    justify-content: center

    .separator
      margin: 0 5px
      line-height: 16px

    .link
      @mixin roboto-regular
      color: $automation-centre-navigator-menu-links-green
      font-size: 12px
      line-height: 16px
      letter-spacing: 0.4px

  .searchBox
    width: 90%
    margin: 10px auto
    margin-top: 20px
    margin-bottom: -10px
    position: relative

    .searchInput
      border-radius: 4px
      border: 1px solid $automation-centre-search-input-border-color
      padding: 0 0 0 32px
      background-color: $automation-centre-search-input-background

      &:focus-within
        border: 1px solid $automation-centre-search-input-active-border-color

      input
        border-bottom: none

      i
        color: $automation-centre-search-input-close-active !important

  .icon
    position: relative
    left: 10px
    bottom: 32px

@media screen and (max-width: $breakpoint--tablet + 1)
  .menu
    border-radius: 0
    margin: 0
    width: $automation-centre-menu-width-small

    .menuVersion
      margin: 0 !important

    .menuItem
      .appsMenuWrapper
        left: $automation-centre-menu-width - 84px

@media screen and (max-width: $breakpoint--desktop-large + 1)
  .menu
    border-radius: 0
    margin: 0
