@import './_all_vars.sass'
.layoutContainer
  display: flex
  flex-direction: column
  width: 100%

  .divider
    border-bottom: 1px solid $automation-centre-divider-color

  .hero
    background-color: $automation-centre-hero-background
    width: 100%
    text-align: center
    min-height: 360px

  .section
    padding: 30px 0 50px 0

    .sectionTitle
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center

      .scrollButtonsContainer
        display: inline-flex
        gap: 8px
        .scrollButtons
          background: white
          color: $automation-centre-aurecon-grey-primary
          border: 1px solid $automation-centre-aurecon-grey-primary !important
           
          &:hover
            background: $automation-centre-aurecon-grey-primary
            color: white
          &:active
            border: 1px solid $automation-centre-aurecon-grey-secondary
            background: $automation-centre-aurecon-grey-secondary
            color: white

      h2
        font-size: 24px
        text-align: left

    .featuredItem
      padding: 10px 12px
  
    .sectionItem
      div
        display: flex
        background-color: $automation-centre-map-placeholder-background
        min-height: 268px
        text-align: center
        align-items: center
        width: 100%
        justify-content: center

    .recommendations
      display: flex
      column-gap: 24px
      overflow-x: auto
      padding: 8px 0
      width: 100%
      &::-webkit-scrollbar 
        width: 0
        height: 0
      .recommendationLoader
        width: 100dvw
        display: flex
        justify-content: center

      .recommendedItem
        width: clamp(300px, 100%, 369px)


