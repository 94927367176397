@import './_all_vars.sass'

.container
  width: 100%
  max-width: 100% !important

  .page
    display: flex
    flex-direction: column
    width: 100%
    height: 100vh
    background-color: white
    overflow: auto

  .pageContents
    display: flex
    flex-direction: column
    width: 100%
    padding: 40px

    .backButton
      border: 1px solid $automation-centre-aurecon-grey-primary !important
      color: $automation-centre-aurecon-grey-primary
      
      &:hover
        background-color: $automation-centre-aurecon-grey-primary !important
        border: 1px solid $automation-centre-aurecon-grey-primary !important
      
      &:active
        background-color: $automation-centre-aurecon-grey-secondary !important
        border: 1px solid $automation-centre-aurecon-grey-secondary !important

  h1
    font-family: "Roboto", sans-serif
    font-size: 28px
    font-weight: 400
    color: $automation-centre-heading

  h2
    font-family: "Roboto", sans-serif
    font-size: 22px
    font-weight: 400
    color: $automation-centre-heading

  p
    font-family: "Roboto", sans-serif
    font-weight: 300


  .columns
    display: flex
    flex-direction: row
    width: 100%
    height: 100vh

    .mainPageWrapper
      position: relative
      display: flex
      flex-direction: column
      flex: 1
      overflow: auto
      padding: 0 40px 0 24px
      margin: 0 0 0 16px
      align-items: center

@media screen and (max-width: $breakpoint--tablet + 1)
  .page
    .pageContents
      padding-right: 24px

      h1
        font-size: 30px
        margin: 8px 0

      h2
        font-size: 24px
        margin: 4px 0

    .columns
      .mainPageWrapper
        margin: 0px
        padding: 0 4px

@media screen and (max-width: $breakpoint--mobile)
  .page
    .pageContents
      width: 100%

@media screen and (min-width: $breakpoint--mobile)
  .page
    .pageContents
      width: 100%

@media screen and (min-width: $breakpoint--tablet)
  .page
    .pageContents
      width: 100%

.manage
    width: 100%
    max-width: 100% !important
    background-color: #FAFAFA
