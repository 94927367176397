@import './_all_vars.sass'

.hasError
  border: 1px solid $automation-centre-form-message-error !important

.fileUploadContainer
  display: flex
  flex-direction: column
  width: 100%
  border-radius: 4px
  margin: 10px 0px
  
  .filesBox
    justify-content: center 
    text-align: center
    width: 100%
    height: 160px
    background-color: $automation-centre-background-light-primary
    display: flex
    flex-direction: column

    .fileIcon
      color: $automation-centre-macroupload-file-icon-color
      padding-bottom: 8px

    .fileName
      padding-top: 8px
      @include roboto-regular
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.25px

    img
      max-width: 100%
      height: 160px
      margin: 0px auto



  .dropzoneBox
    padding: 40px
    justify-content: center 
    text-align: center
    cursor: pointer
    width: 100%
    height: 160px
    background-color: $automation-centre-background-light-primary
    border-radius: 4px

    .dropzoneIcon
      color: $automation-centre-aurecon-granite-grey
      margin: 5px

    .instructionText
      @include roboto-regular
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.25px

    .infoText
      @include roboto-regular
      font-size: 12px
      line-height: 16px
      letter-spacing: 0.4px

      display: flex
      align-items: center
      justify-content: center
      padding-top: 8px
      gap: 8px

  .trashIconRow
    background-color: $automation-centre-background-light-primary
    width: 100%
    height: 24px
    display: flex
    justify-content: flex-end
    align-items: center
    padding-right: 16px
    padding-bottom: 16px
    border-radius: 4px

    .trashIcon
      margin-left: auto

      .buttonStyle
        color: $automation-centre-aurecon-grey-primary 
        background-color: $automation-centre-aurecon-primary-green 
        border: 1px solid $automation-centre-aurecon-primary-green

        &:hover
            border: 1px solid $automation-centre-aurecon-grey-primary 
            background-color: $automation-centre-aurecon-grey-primary 
            color: $automation-centre-background-light-primary 
            
        &:active
            background-color: $automation-centre-aurecon-grey-secondary 
            color: $automation-centre-background-light-primary 

        &:disabled
            border: 1px solid $automation-centre-button-primary-background-disabled 
            background-color: $automation-centre-button-primary-background-disabled
            color: $automation-centre-button-primary-color-disabled
            cursor: not-allowed

.dropzoneToolTip
  width: 304px

  .tooltipHeading
    @include roboto-medium
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.1px
    text-align: left
    padding-bottom: 16px


  .tooltipBody
    @include roboto-regular
    font-size: 12px
    line-height: 16px
    letter-spacing: 0.4px
    text-align: left

.errorMessage
  @include roboto-regular
  font-size: 12px
  line-height: 16px
  margin-left: auto
  color: $automation-centre-form-message-error
  margin-top: 8px
  width: 100%


         

