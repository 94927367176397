@import './_all_vars.sass'

.loader
  width: 100%
  height: 100%
  min-height: 200px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  i
    padding: 12px

.loadingModal
  .loadingModalContainer
    color: $automation-centre-main-dark

    h2
      font-size: 24px
      width: 100%

  .actionButton
    margin-left: 16px

