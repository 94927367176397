@import '../../_all_vars.sass'

.modalContainer      
    div:global(.modal)
        height: 800px
        i:global(.modal-close-icon)
            color: $automation-centre-aurecon-grey-primary !important
            background: none
    .iconRow
        @include roboto-regular
        font-size: 24px
        line-height: 32px
        word-wrap: break-word
        padding: 12px 0px !important

    .modalContent
        margin-top: 24px
        height: 100%
        display: flex
        flex-direction: column
        gap: 24px

        .topContainer
            display: flex
            flex-direction: row
            padding: 20px
            .detailsContainer
                display: flex
                flex-direction: column
                width: 50%
            .replaceButtonContainer
                display: flex
                justify-content: flex-end
                width: 50%
                align-items: flex-end

        .detailsLabel
            font-weight: 500
        .detailsText
            margin-top: 20px

        .featuredEditorContainer
            padding: 0px 20px
            .editorContainer
                margin-top: 10px
        .fileUploadContainer
            padding: 0px 20px
            .topFeaturedDropboxContainer
                margin-top: 10px
            .dropboxContainer
                display: flex
                margin-top: 10px
                .dropbox
                    margin: 0px 20px
                    width: 50%

        .automationsFilter
            padding: 0px !important

    .secondaryButton
        border-radius: 20px !important
        padding: 8px 16px !important
        @include atc-btn-secondary

    .primaryButton
        border-radius: 20px !important
        padding: 8px 16px !important
        @include atc-btn-primary-green

    .searchfilterContainer
        flex: 1
        display: flex
        justify-content: flex-end
        margin-top: 40px
        align-items: flex-start
        flex-direction: row

        .searchBox 
            width: 100%
            margin-bottom: -10px
            position: relative
        

        .searchInput
            border-radius: 4px
            border: 1px solid $automation-centre-search-input-border-color
            padding: 0 0 0 32px
            background-color: $automation-centre-search-input-background

            &:focus-within
                border: 1px solid $automation-centre-search-input-active-border-color
            
            input
                border-bottom: none
        .icon
            position: relative
            left: 10px
            bottom: 32px