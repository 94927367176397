@import './_all_vars.sass'

.layoutContainer
    margin: 40px 0 0 0
    display: flex
    flex-direction: column
    width: 100%
    justify-content: space-between
    .header
        .pageTitlte
            margin: 40px 0 40px 0
            text-align: left
            font-size: 28px
            line-height: 36px
    .searchContainer
        display: flex
        flex-direction: row
        gap: 16px
        align-items: center
        margin-top: 24px
        .addUserBtn
            @include atc-btn-primary-grey
            height: 40px !important
            width: 40px !important
            font-size: 14px
        .searchFormInput
            input
                padding: 9px 12px
                border: 1px solid
                border-radius: 4px
                @include roboto-regular
                font-size: 14px
                line-height: 20px
                letter-spacing: 0.25px
                word-wrap: break-word

        .permissionHistoryBtn
            @include atc-btn-secondary
            height: 40px
            border-radius: 25px
            padding: 8px 16px

    .tableContainer
        margin-top: 40px

        table
            thead
                tr
                    border: none !important
                td
                    @include roboto-medium
                    background-color: $automation-centre-upload-automation-column-background !important
                    padding: 12px !important
                    div
                        display: inline-flex
                        align-items: center
                    &:hover
                        background-color: $automation-centre-aurecon-grey-primary !important
                        color: $automation-centre-background-light-primary !important
                    &:active
                        background-color: $automation-centre-aurecon-grey-secondary !important
                        color: white !important
            tbody
                td.systemAdmin
                    background-color: $automation-centre-structure-page-background !important
                tr
                    &:hover
                        td
                            background-color: $automation-centre-text-menu-background-hover !important

        .capabilityContainer
            display: flex !important
            width: auto
            align-items: center
            color: $automation-centre-aurecon-granite-grey

        .capabilityTooltipContainer
            .capabilityTooltipContent
                cursor: default
                display: flex
                align-items: center
                .iconLabel
                    margin: 0px 5px
                    font-size: 14px

        .action
            position: relative
            display: flex
            justify-content: flex-end
            .actionBtn
                @include atc-btn-primary-grey
                &:hover
                    cursor: pointer 
            
            .contextMenuContainer
                position: absolute
                top: 108%
                right: 0
                border: 1px solid $automation-centre-aurecon-grey-primary
                z-index: 1000
                width: 154px
                height: 80px, 
                background: white, 
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.30) 
                border-radius: 4px 
                overflow: hidden 
                flex-direction: column
                justify-content: flex-start
                align-items: flex-start
                display: inline-flex
                .contextMenu
                    user-select: none
                    display: flex
                    justify-content: flex-start
                    align-items: center
                    padding: 8px 12px
                    height: 100%
                    width: 100%
                    &:hover
                        background-color: $automation-centre-aurecon-grey-secondary 
                        color: white
                        cursor: pointer
                .contextMenuDisabled
                    background-color: white !important
                    color: $automation-centre-aurecon-grey-light
                    &:hover
                        background-color: white !important
                        color: $automation-centre-aurecon-grey-light

    
    .paginationContainer
        display: flex
        justify-content: flex-end
        align-items: center
        gap: 8px
        margin-top: 40px
        .pageButtonsContainer
            display: inline-flex
            gap: 8px
            .pageButtons
                background: white
                color: $automation-centre-aurecon-grey-primary
                border: 1px solid $automation-centre-aurecon-grey-primary !important
                &.active
                    border: 1px solid $automation-centre-aurecon-grey-secondary
                    background: $automation-centre-aurecon-grey-secondary !important
                i
                    font-size: 36px !important

        .pageReference
            margin-right: 12px
            p
                @include roboto-medium
                font-size: 14px
                line-height: 20px
            input
                width: 40px
                height: 40px
                text-align: center
