@import '../../_all_vars'

.removeApproverModal
    div:global(.modal)
        height: 256px !important
        i:global(.modal-close-icon)
            color: $automation-centre-aurecon-grey-primary !important
            background: none 
    .iconRow
        h2
            @include roboto-regular
            font-size: 24px
            line-height: 32px
            word-wrap: break-word
    .modalContent
        @include roboto-regular
        color: #171A16
        font-size: 14px
        line-height: 20px
        letter-spacing: 0.25px
        word-wrap: break-word
        .userName
            @include roboto-medium
    
    .secondaryButton
        border-radius: 20px !important
        padding: 8px 16px !important
        @include atc-btn-secondary

    .primaryButton
        border-radius: 20px !important
        padding: 8px 16px !important
        @include atc-btn-primary-green