$automation-centre-main-dark: #263238;
$automation-centre-main-light: #00796b;
$automation-centre-pill-in-progress: #4418c2;
$automation-centre-pill-closed: #c26a18;
$automation-centre-pill-public: #246b03;
$automation-centre-card-border: #d4d4d4;
$automation-centre-settings-header-border: #d4d4d4;
$automation-centre-nav-button-color: #b2dfdb;
$automation-centre-editor-tool-bar-background: #1b4747;

$automation-centre-background-light-primary: white;
$automation-centre-aurecon-primary-green: #89C925;

$automation-centre-status-not-started: #fff;
$automation-centre-status-in-progress: #f6d510;
$automation-centre-status-internal-review: #14a4de;
$automation-centre-status-awaiting-external-review: #843dbc;
$automation-centre-status-external-review-open: #de8d14;
$automation-centre-status-external-review-closed: #da3b20;
$automation-centre-status-approved: #00bd39;
$automation-centre-status-creating-revision: #00eeff;
$automation-centre-status-creating-revision-error: #f80a0a;
$automation-centre-status-cloning-maps: #0077ff;

$automation-centre-modal-background-opacity: rgba($automation-centre-main-light, 0.2);
$automation-centre-lock-own: #00bd39;
$automation-centre-lock-other: #de8d14;

$automation-centre-dropdown-primary: #4E5859;
$automation-centre-dropdown-border: #373A36;
$automation-centre-dropdown-background: #FFFFFF;
$automation-centre-dropdown-icon: $automation-centre-aurecon-primary-green;
$automation-centre-dropdown-text: #121212;
$automation-centre-dropdown-border-disabled: #91948F;
$automation-centre-dropdown-text-disabled: #91948F;

$automation-centre-combobox-primary: #4E5859;
$automation-centre-combobox-border: #373A36;
$automation-centre-combobox-background: #FFFFFF;
$automation-centre-combobox-icon: $automation-centre-aurecon-primary-green;
$automation-centre-combobox-text: #121212;
$automation-centre-combobox-checkbox: #373A36;

$automation-centre-map-view-button-hover: #004d40;
$automation-centre-drap-indicator: #919191;
$automation-centre-text-light: #666;
$automation-centre-text-disabled: #888;

$automation-centre-text-reverse: $automation-centre-dropdown-background;

$automation-centre-navigator-menu-background: #fafafa;
$automation-centre-navigator-menu-open: #f2f2f2;
$automation-centre-navigator-menu-active-background: rgba(0, 121, 107, 0.1);
$automation-centre-settings-menu-active-background: rgba(0, 121, 107, 0.1);
$automation-centre-navigator-menu-active-font: #004d40;
$automation-centre-navigator-menu-links-green: #85C446;
$automation-centre-navigator-menu-pill-color: $automation-centre-aurecon-primary-green;

$automation-centre-structure-page-background: #fafafa;
$automation-centre-structure-chapter-background: #f2f2f2;

$automation-centre-map-preview-background-color: #e5e5e5;
$automation-centre-map-tag: #c2185b;
$automation-centre-input-character-left: #757575;
$automation-centre-form-input: #666666;
$automation-centre-user-search-item-color: #004053;
$automation-centre-user-search-item-hover: #d9e9e7;

$primary--colour--main: $automation-centre-main-light;
$primary--colour--main--dark: $automation-centre-main-dark;
$primary--colour--main--light: $aurecon--cobaltblue--300;

$pill--colour--1: $automation-centre-pill-in-progress;
$pill--colour--2: $automation-centre-pill-closed;
$pill--colour--3: $automation-centre-pill-public;
$pill--colour--4: $automation-centre-main-light;
$pill--colour--5: $automation-centre-map-tag;
$pill--colour--16: $aurecon--cobaltblue--050;

$automation-centre-status-warning: #da3b20;
$automation-centre-status-success: #00BD39;
$automation-centre-status-info: #14A4DE;
$automation-centre-status-tracking: #8505D4;

$automation-centre-map-placeholder-background: #fafafa;

$automation-centre-icon-colour-opacity: rgba($automation-centre-main-light, 0.3);

$automation-centre-time-slider-max-min-date-color: #515252;
$automation-centre-time-slider-current-date-color: #6d9cce;

$automation-centre-create-new-theme-base-theme-background-color: #f2f2f2;
$automation-centre-create-new-colour-input-wrapper-background-color: #fafafa;

$automation-centre-manage-feedback-type-background-color: #f2f2f2;
$automation-centre-manage-feedback-type-border-color: #f2f2f2;
$automation-centre-manage-feedback-type-border-color-active: $automation-centre-main-light;
$automation-centre-manage-feedback-type-border-color-hover: $automation-centre-main-light;
$automation-centre-manage-feedback-link-text-color: #121212;

$automation-centre-external-feedback-field-background-color: #f2f2f2;

$automation-centre-feedback-button-disabled-color: #919191;

$automation-centre-search-content-icon-color: #00796B;
$automation-centre-search-content-input-background-active: #eee;

$automation-centre-report-setting-logo-container-text-color: #121212;
$automation-centre-report-setting-logo-container-text-highlight-color: #00796B;
$automation-centre-report-setting-logo-container-note-add-icon-color: #c4c4c4;

$automation-centre-text-menu: #4E5859;
$automation-centre-text-menu-version: #666666;
$automation-centre-text-menu-background-active: #DCE2E0;
$automation-centre-text-menu-background-hover: #DEEFC2;
$automation-centre-text-menu-color-hover: #000;
$automation-centre-menu-background: #eee;
$automation-centre-menu-active: #ddd;
$automation-centre-menu-width: 275px;
$automation-centre-menu-width-small: 48px;
$automation-centre-menu-logo-width: 90%;
$automation-centre-menu-logo-width-small: 48px;
$automation-centre-heading: #373A36;
$automation-centre-search-input-background: #fff;
$automation-centre-search-input-border-color: #BBC6C3;
$automation-centre-search-input-active-border-color: #4E9101;
$automation-centre-search-input-close-active: $automation-centre-aurecon-primary-green;

$automation-centre-hero-background: #373A36;
$automation-centre-divider-color: #BBC6C3;

$automation-centre-view-btn-color: $automation-centre-aurecon-primary-green;
$automation-centre-viewer-text-color: #121212;


$automation-centre-upload-automation-input-border: #373A36;
$automation-centre-upload-automation-input-background: #FFFFFF;
$automation-centre-upload-automation-column-background: #FAFAFA;

$automation-centre-pill-text-color: #4E5859;
$automation-centre-upload-automation-pill-source: #DAE8CD;
$automation-centre-upload-automation-pill-capability: #E2F0F5;
$automation-centre-upload-automation-pill-practise: #DFDDF8;
$automation-centre-upload-automation-pill-region: #E0E0E0;
$automation-centre-upload-automation-pill-originator: #E0E0E0;
$automation-centre-featured-card-metadata-color: #8E9C9C;

$automation-centre-hero-welcome-color: $automation-centre-aurecon-primary-green;
$automation-centre-hero-message-color: #BBC6C3;
$automation-centre-take-a-tour-hover-color: $automation-centre-hero-welcome-color;
$automation-centre-hero-featured-background-color: rgba(#373A36, 0.7);
$automation-centre-hero-featured-background-color-hover: rgba(#373A36, 0.8);
$automation-centre-featured-card-background: #F5F5F5;
$automation-centre-featured-card-title-color: #000000;
$automation-centre-featured-card-metadata-color: #8E9C9C; 
$automation-centre-featured-card-hover-border-color: $automation-centre-heading;
$automation-centre-featured-text-tag-color: $automation-centre-text-menu;
$automation-centre-upload-automation-scriptlibarary-button-background: $automation-centre-aurecon-primary-green;

$automation-centre-dark-text-color: $automation-centre-heading;

$automation-centre-aurecon-grey-primary: #373A36;
$automation-centre-aurecon-grey-secondary: #4E5859;
$automation-centre-aurecon-granite-grey: #666666;
$automation-centre-aurecon-grey-light: #91948F;
$automation-centre-aurecon-grey-light-2: #EBEBEB;
$automation-centre-aurecon-grey-light-3: #FAFAFA;
$automation-centre-aurecon-silver: #BBC6C3;

$automation-centre-aurecon-chinese-black: #121212;
$automation-centre-aurecon-raven-black: #171A16;


$automation-centre-aurecon-peach: #F5CABE;

$automation-centre-aurecon-yellow: #FCD672;
$automation-centre-aurecon-yellow--60: rgba($automation-centre-aurecon-yellow, 0.6);

$automation-centre-aurecon-green-primary: #89C925;
$automation-centre-aurecon-green-secondary: #3CB2B1;
$automation-centre-aurecon-green-secondary--60: rgba($automation-centre-aurecon-green-secondary, 0.6);

$automation-centre-tag-color: #9EE8B1;
$script-library-tag-color: #A7D4D4;



$automation-centre-information-title-background: rgba(#373A36, 0.7);

$automation-centre-clear-icon: $automation-centre-aurecon-primary-green;
$automation-centre-termsconditionsmodal-divider-color: #D8DFE2;
$automation-centre-termsconditionsmodal-border-color: #D8DFE2;
$automation-centre-termsconditionsmodal-declinebutton-color: #373A36;
$automation-centre-termsconditionsmodal-acceptbutton-color: #4E5859;

$automation-centre-macroupload-browse-text-color: $automation-centre-aurecon-primary-green;
$automation-centre-macroupload-file-extension-color: #4E5859;
$automation-centre-macroupload-file-icon-color: #BDBDBD;
$automation-centre-macroupload-cancel-icon-color: #373A36;
$automation-centre-macroupload-delete-icon-color: #4E5859;
$automation-centre-macroupload-info-icon-color: #373A36;

$automation-centre-fileupload-progress-text-color: #171A16;
$automation-centre-fileupload-progress-bar-color:#373A36;

$automation-centre-termsconditionsmodal-divider-color: #D8DFE2;
$automation-centre-termsconditionsmodal-border-color: #D8DFE2;
$automation-centre-termsconditionsmodal-declinebutton-color: #373A36;
$automation-centre-termsconditionsmodal-acceptbutton-color: #4E5859;
$automation-centre-button-primary-color-disabled: #91948F;
$automation-centre-button-primary-background-disabled: #E5E9E4;
$automation-centre-active-input-border-green: #80A367;

$automation-centre-form-message-error: #f80a0a;

$automation-centre-likes-enabled: #8E9C9C;
$automation-centre-search-filter: #FAFAFA;
$aurecon-centre-mention-color: #1916C0;
