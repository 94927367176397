@import '../_all_vars.sass'

.paginationContainer
    display: flex
    justify-content: flex-end
    align-items: center
    gap: 8px
    margin-top: 40px
    .pageButtonsContainer
        display: inline-flex
        gap: 8px
        .pageButtons
            background: white
            color: $automation-centre-aurecon-grey-primary
            border: 1px solid $automation-centre-aurecon-grey-primary !important
            &.active
                border: 1px solid $automation-centre-aurecon-grey-secondary
                background: $automation-centre-aurecon-grey-secondary !important
            i
                font-size: 36px !important

    .pageReference
        margin-right: 12px
        p
            @include roboto-medium
            font-size: 14px
            line-height: 20px
        input
            width: 40px
            height: 40px
            text-align: center