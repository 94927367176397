@import ./_all_vars.sass

.heroContainer
    @include roboto-regular
    display: inline-flex
    color: white
    width: 100%
    height: 100%
    min-height: 360px
    justify-content: flex-start
    align-items: flex-start
    overflow: hidden
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px !important

    .welcomePanel
        display: flex
        flex-shrink: 1
        height: 100%
        flex-flow: column wrap
        padding: 40px
        gap: 8px
        justify-content: flex-start
        background-color: $automation-centre-hero-background
        width: 200px

        .welcome
            color: $automation-centre-hero-welcome-color
            font-size: 16px
        
        .user
            font-size: 20px
            line-height: 30px
      
        
        .message
            color: $automation-centre-hero-message-color
            font-size: 14px
            line-height: 20px
            letter-spacing: 0.25px
            word-wrap: break-word
        
        .takeATour
            @include roboto-medium
            line-height: 20px
            letter-spacing: 0.25px
            margin-top: auto
            text-align: left
            color: white
            &:hover
                color: $automation-centre-take-a-tour-hover-color!important 
        
    .heroImage
        padding: 32px
        align-items: flex-end
        justify-content: flex-end
        background-position: top right
        background-repeat: no-repeat
        background-size: cover
        height: 100%
        border-bottom-right-radius: inherit
        

        .featuredDetails
            max-width: 45%
            min-width: 200px
            padding: 16px
            background-color: $automation-centre-hero-featured-background-color
            line-height: 20px
            font-size: 14px
            border-radius: 6px
            color: white !important
            text-decoration: none
            cursor: pointer
            border: 1px solid #fff0

            &:hover
                background-color: $automation-centre-hero-featured-background-color-hover
                border: 1px solid #fff9

            .featuredTitle
                @include roboto-medium
                margin-bottom: 8px
                letter-spacing: 0.1px
                word-wrap: break-word
                overflow: hidden
                text-overflow: ellipsis
                display: -webkit-box
                -webkit-line-clamp: 1
                -webkit-box-orient: vertical
                font-size: 20px
                font-weight: 400

            .featuredDescription
                @include roboto-regular
                letter-spacing: 0.25px
                word-wrap: break-word
                overflow: hidden
                text-overflow: ellipsis
                display: -webkit-box
                -webkit-line-clamp: 3
                -webkit-box-orient: vertical
                font-size: 14px
                font-weight: 400
