@import './_all_vars.sass'

@mixin linkified
    .linkified
        a
            @include roboto-medium
            font-size: 14px
            line-height: 20px
            letter-spacing: 0.25px
            word-wrap: break-word
            overflow-wrap: break-word
            word-break: break-all
    

@mixin comment-header
    .commentHeader
        display: flex
        gap: 10px
        .commentIcon
            .circleIcon
                @include roboto-medium
                width: 40px
                height: 40px
                background-color: $automation-centre-aurecon-grey-light-2
                border-radius: 50%
                display: flex
                justify-content: center
                align-items: center
        .commentAuthor
            display: flex
            flex-direction: column
            .authorName
                @include roboto-medium
                font-size: 14px
                line-height: 20px
                color: black
            .createdAt
                @include roboto-regular
                font-size: 12px
                color: $automation-centre-aurecon-granite-grey

@mixin comment-content
    .commentText
        @include roboto-medium
        font-size: 14px
        line-height: 20px
        color: black
        letter-spacing: 0.25px
        margin-bottom: 24px
        word-wrap: break-word
    .commentControls
        display: inline-flex
        gap: 24px
        justify-content: flex-start
        align-items: center
        .actionButton
            display: inline-flex
            gap: 4px
            justify-content: flex-start
            align-items: center
            cursor: pointer
            span
                @include roboto-medium
                font-size: 14px
                letter-spacing: 0.1px
                line-height: 20px
                color: $automation-centre-aurecon-grey-primary
            .actionIcon
                font-family: 'Material Symbols Outlined'
                font-size: 18px
                font-weight: 400
                color: $automation-centre-aurecon-grey-primary
    .replyInputContainer
        margin-top: 24px  

.mention
    color: $aurecon-centre-mention-color


.commentsWrapper
    margin-top: 40px
    .accordionPanel
        border-bottom: none !important
        padding: 0
        .headingHolder
            margin: 40px 0
            h2
                @include roboto-medium
                font-size: 22px
                line-height: 28px
                color: $automation-centre-aurecon-grey-primary
                margin: 0
        :global(.accordion-panel-icon)
            color: $automation-centre-aurecon-green-primary !important
    
    .accordionContent
        padding: 0
        border-bottom: none !important
        .commentFormContainer
            border-bottom: none !important
            .inputError
                color: $automation-centre-form-message-error
                font-family: Arial, sans-serif
                font-size: 12px
                font-style: normal
                font-weight: 400
                line-height: 18px
                margin-top: 8px
            .commentInput
                textarea
                    border-radius: 4px
                    border: 1px solid $automation-centre-aurecon-grey-primary
                    padding: 10px 16px 8px 16px
                    resize: vertical
                    &:hover, &:active, &:focus
                        border: 1px solid $automation-centre-aurecon-green-primary
                        
            .replyInput
                textarea
                    border-radius: 4px
                    border: 1px solid $automation-centre-aurecon-grey-primary
                    padding: 10px 16px 8px 16px
                    resize: vertical
                    height: 43px
                    &:hover, &:active, &:focus
                        border: 1px solid $automation-centre-aurecon-green-primary
            .hasError
                color: $automation-centre-form-message-error
                margin: 5px 0 0 0
                  
            .commentButtonsSecondary
                margin: 8px 0 0 0
                display: flex
                justify-content: flex-end
                button
                   @include atc-btn-secondary
                   border-radius: 20px
                   padding: 8px 16px
            
            .commentButtonsGreenPrimary
                margin: 8px 0 0 0
                display: flex
                justify-content: flex-end
                button
                    @include atc-btn-primary-green
                    border-radius: 20px
                    padding: 8px 16px
                
        
        .commentListContainer
            .commentItem
                margin: 0 0 24px 0
                @include comment-header
                .commentContent
                    background: $automation-centre-aurecon-grey-light-3
                    padding: 24px
                    border-radius: 4px
                    margin: 16px 0 0 48px
                    @include comment-content
                    .commentText
                        @include linkified


            
            .replyItem
                margin: 40px 0 0 0
                @include comment-header
                .commentContent
                    background: $automation-centre-aurecon-grey-light-3
                    padding: 24px
                    margin: 8px 0 0 48px
                    @include comment-content
                    .commentText
                        margin-bottom: 18px !important
                        @include linkified
                        
                    
                     

    .listComponentWrapper
        .viewAllRepliesButton
            @include roboto-medium
            margin: 24px 0 0 0 !important

            font-size: 14px
            line-height: 20px
            letter-spacing: 0.1px
            &:hover
                text-decoration: underline
        .viewMoreBtnContainer
            display: flex
            justify-content: center
            margin-top: 24px
            .viewMoreButton
                @include atc-btn-secondary
                border-radius: 20px