@import './_all_vars.sass'

.automationCard
    width: 100%
    border-radius: 8px
    padding: 16px
    border: 1px solid $automation-centre-search-input-border-color
    cursor: pointer

    .thumbnailContainer
        display: flex
        justify-content: space-between
        align-items: flex-start

        .thumbnailSection
            display: flex
            align-items: center
            width: 56px
            height: 56px

            .loader
                margin: auto
            
            img
                width: 56px
                height: 56px
                border-radius: 8px

        .cardButtons
            border: 1px solid $automation-centre-pill-text-color !important
            background-color: $automation-centre-pill-text-color
            

    .automationTitle
        margin-top: 16px
        font-weight: 500
        font-size: 16px
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical

    .metadata
        @include roboto-medium
        margin-top: 4px
        color: $automation-centre-featured-card-metadata-color
        display: inline-flex
        justify-content: flex-start
        align-items: center 
        gap: 16px
        font-size: 14px
        line-height: 16px
        letter-spacing: 0.50px
        word-wrap: break

    .description
        @include roboto-regular
        margin-top: 12px
        font-size: 14px
        overflow: hidden
        line-height: 20px
        letter-spacing: 0.25px
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical

    .tags
        margin-top: 12px

            
    &:hover
        border: 1px solid $automation-centre-aurecon-grey-primary
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)


@media screen and (max-width: $breakpoint--tablet + 1)
    .automationCard
        min-width: 230px