@import '../../_all_vars.sass'

.modalContainer      
    div:global(.modal)
        height: 800px
        i:global(.modal-close-icon)
            color: $automation-centre-aurecon-grey-primary !important
            background: none
    .iconRow
        @include roboto-regular
        font-size: 24px
        line-height: 32px
        word-wrap: break-word
        padding: 12px 0px !important

    .modalContent
        margin-top: 24px
        height: 100%
        display: flex
        flex-direction: column
        gap: 24px

        .filters
            padding: 0px 24px !important

        table
            thead
                tr
                    border: none !important
                td
                    @include roboto-medium
                    background-color: $automation-centre-upload-automation-column-background !important
                    padding: 12px !important
                    div
                        display: inline-flex
                        align-items: center
                    &:hover
                        background-color: $automation-centre-aurecon-grey-primary !important
                        color: $automation-centre-background-light-primary !important
                    &:active
                        background-color: $automation-centre-aurecon-grey-secondary !important
                        color: white !important
            tbody
                tr
                    &:hover
                        td
                            background-color: $automation-centre-text-menu-background-hover !important

    .loadingContainer
        margin: 100px auto

    .searchfilterContainer
        .searchBox 
            width: 100%
            position: relative

        .searchInput
            border-radius: 4px
            border: 1px solid $automation-centre-search-input-border-color
            padding: 0 0 0 32px
            background-color: $automation-centre-search-input-background

            &:focus-within
                border: 1px solid $automation-centre-search-input-active-border-color
            
            input
                border-bottom: none
        .icon
            position: relative
            left: 10px
            bottom: 32px

    .automationFilters
        padding: 0px 24px !important

    .radioColumn
        width: 5%

    .titleColumn
        width: 65%

    .sourceColumn
        width: 15%

        .pill
            background-color: $automation-centre-upload-automation-pill-source !important
            height: 24px
            width: 90px
            justify-content: center
            cursor: pointer
    
    .dateColumn
        width: 10%

    .actionColumn
        width: 5%



.radioButton
    background-color: green
    display: flex
    justify-content: center
    align-items: center
    margin: auto
    .radio-wrapper .radios label.radio input
        margin: auto
        border: none
        border-color: pink !important
        border-radius: 50% important

.actionContainer
    display: flex
    justify-content: center
    
    .iconButton
        background: $automation-centre-aurecon-grey-secondary
        border-radius: 50%
        padding: 10px
        color: white
        cursor: pointer
        height: fit-content
        width: fit-content

.customRadio
  display: inline-block
  margin: 8px

  input[type="radio"]
    display: none

  .checkmark
    width: 20px
    height: 20px
    border: 2px solid $automation-centre-aurecon-grey-secondary
    border-radius: 50%
    display: inline-block
    position: relative
    cursor: pointer

    &:after
      content: ""
      width: 10px
      height: 10px
      background-color: $automation-centre-aurecon-grey-secondary
      border-radius: 50%
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      opacity: 0
      margin: auto

  input[type="radio"]:checked + .checkmark
    background-color: white
    border-color: $automation-centre-aurecon-grey-secondary

    &:after
      opacity: 1


.sectionTitle
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding-bottom: 40px
    margin-left: auto
    padding-top: 24px

    .pageButtonsContainer
      display: inline-flex
      gap: 8px
      .pageButtons
        background: white
        color: $automation-centre-aurecon-grey-primary
        border: 1px solid $automation-centre-aurecon-grey-primary !important
        &.active
          border: 1px solid $automation-centre-aurecon-grey-secondary
          background: $automation-centre-aurecon-grey-secondary !important
        i
          font-size: 36px !important

    .pageReference
        margin-right: 12px
        p
            @include roboto-medium
            font-size: 14px
            line-height: 20px

.secondaryButton
    border-radius: 20px !important
    padding: 8px 16px !important
    @include atc-btn-secondary

.primaryButton
    border-radius: 20px !important
    padding: 8px 16px !important
    @include atc-btn-primary-green
