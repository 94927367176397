@import '../../_all_vars'

.featuredorderFormModal
    div:global(.modal)
        width: 444px
        height: 488px
    .iconRow
        h2
            padding: 0
            margin: 16px 0 0 0
            @include meta-pro-regular
            font-size: 24px
            line-height: 32px
            word-wrap: break-word
        .subheader
            @include roboto-regular
            font-size: 14px
            line-height: 20px
            letter-spacing: 0.25px
            margin: 24px 0 16px 0
            word-wrap: break-word
            width: 100%
    .modalContent
        .positions
            .positionIndicatorHeader
                @include roboto-medium
                line-height: 20px
                color: #171A16
                padding: 12px 16px
            .positionIndicator 
                padding: 8px 16px 12px 16px
        .featuredAutomations
            .featuredItemHeader
                @include roboto-medium
                line-height: 20px
                color: #171A16
                padding: 12px 16px
            .draggableContainer
                width: 100%
            .featuredItem
                background: #FAFAFA
                padding: 8px 16px 12px 16px
                margin-bottom: 8px
                .featuredItemContainer
                    display: flex
                    flex-direction: row
                    align-items: center
                    width: 100%
                    &.dragging
                        opacity: 0
            
                    .dragIndicator
                        margin-right: 22px
                        color: #919191
                    .featuredTitle
                        @include roboto-regular
                        line-height: 20px
                        color: #171A16

                    .openButton
                        margin-left: auto
                        border: 1px solid $automation-centre-pill-text-color !important
                        background-color: $automation-centre-pill-text-color
        
    .secondaryButton
        border-radius: 20px !important
        padding: 8px 16px !important
        @include atc-btn-secondary 

    .primaryButton
        border-radius: 20px !important
        padding: 8px 16px !important
        @include atc-btn-primary-green

.dragPreview
    width: 280px !important
    opacity: 1 !important
    background: #fafafa
    padding: 10px
    display: flex
    flex-direction: row
    align-items: center
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5) !important
    border-radius: 8px


    .dragIndicator
        margin-right: 18px
        color: #919191
    .featuredTitle
        @include roboto-regular
        line-height: 20px
        color: #171A16
        width: 70%

    .openButton
        margin-left: auto !important
        border: 1px solid $automation-centre-pill-text-color !important
        background-color: $automation-centre-pill-text-color !important